import React, { useCallback, useEffect, useState } from 'react'

import { Container, Grid } from '@mui/material'
import { WppBanner, WppToast } from '@wppopen/components-library-react'
import { useOs } from '@wppopen/react'
import { connect } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'

import styles from './AssessmentList.module.scss'
import { AssessmentListProps, rowDataProps } from './interface'
import RowDetails from './rowDetails/RowDetails'
import DataGrid from '../../../components/dataGrid/DataGrid'
import DataGridOfflineComp from '../../../components/dataGrid/DataGridOfflineComp'
import { ASSES_GRID_HEADER_DETAILS, USER_ROLE } from '../../../helper/constants'
import isObjectEmpty, { convertToTitleCase, getLocalDate } from '../../../helper/Helper'
import serviceURL from '../../../helper/serviceURL'
import { launchAssessDispatcher } from '../../dmModule/inventoryList/rowDetails/action'

const mapStateToProps = (state: any) => {
  return {
    selectedSideOrg: state.selectedSideNavOrgRed.data,
    userData: state.getOrgByUserRed.data,
    userContext: state.getOrgByUserRed.context,
    assessRowDetails: state.assessmentRowDetailsRed.data
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  launchAssessDispatcher: (obj: object) => dispatch(launchAssessDispatcher(obj))
})

const AssessmentList = React.memo(
  ({ selectedSideOrg, launchAssessDispatcher, userData, userContext, assessRowDetails }: AssessmentListProps) => {
    const initialRowData = {
      id: '',
      name: '',
      description: '',
      inventoryId: '',
      inventoryType: '',
      categories: '',
      status: true
    }
    const pageToStart = 0
    const pageSize = 25
    const [showApproveToast, setShowApproveToast] = useState(false)
    const [clickedRowData, setClickedRowData] = useState<any>(initialRowData)
    const [openClickedRowModal, setOpenClickedRowModal] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [isToShowBanner, setIsToShowBanner] = useState(false)
    const [showPermissionBanner, setShowPermissionBanner] = useState(false)
    let { assessmentId } = useParams()
    const navigate = useNavigate()
    const baseApiUrl = `${serviceURL.pgpBaseAPI}/api/assessments`
    const {
      osApi: { getAccessToken }
    } = useOs()
    const headers = {
      accept: '*/*',
      Authorization: 'Bearer ' + getAccessToken()
    }

    useEffect(() => {
      if (assessmentId) {
        setClickedRowData({ id: assessmentId, name: assessRowDetails?.name })
        setOpenClickedRowModal(true)
      }
    }, [assessmentId, assessRowDetails?.name])

    const isSiteAdmin = userData?.groups.some(group => group?.name === USER_ROLE.SAD)
    const isUserAllowedToSeeAssessment = isSiteAdmin
      ? true
      : selectedSideOrg?.name?.toLowerCase() === userContext?.agency?.toLowerCase()

    const fetchInitialGridList = (startRow: number = 0, endRow: number = pageSize) => {
      if (!isUserAllowedToSeeAssessment) {
        setShowPermissionBanner(true)
        return
      }
      setShowPermissionBanner(false)
      let apiUrl = ''
      if (selectedSideOrg) {
        apiUrl = baseApiUrl + '/search'
        const handleErrors = (response: any) => {
          if (!response.ok) {
            setIsLoading(false)
            setIsToShowBanner(true)
            throw Error(response.statusText)
          }
          return response
        }
        setIsLoading(true)
        fetch(apiUrl, {
          method: 'POST',
          headers: {
            ...headers,
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            page: startRow,
            size: endRow,
            sort: 'createdAt',
            order: 'desc',
            filters: []
          })
        })
          .then(handleErrors)
          .then(httpResponse => httpResponse.json())
          .then(response => {
            if (response) {
              let sanitizedData = prepareRow(response.content)
              if (sanitizedData) {
                setIsToShowBanner(false)
              }
            }
            setIsLoading(false)
          })
          .catch(error => {
            console.error(error)
            setIsLoading(false)
            setIsToShowBanner(true)
          })
      }
    }

    const prepareRow = useCallback((rowData: []) => {
      let rows: any = []
      if (rowData) {
        rowData.forEach((item: rowDataProps) => {
          rows.push({
            id: item.id,
            name: item.name,
            createdBy: item.createdBy,
            createdAt: getLocalDate(item.createdAt),
            status: item.status,
            modifiedAt: getLocalDate(item.modifiedAt),
            modifiedBy: item.modifiedBy,
            description: item.description,
            inventoryType: convertToTitleCase(item.inventoryType?.name),
            respondent: item.respondent && item.respondent.fullName,
            assessmentTemplate: item.assessmentTemplate && item.assessmentTemplate.name,
            assessor: item.assessor && item.assessor.email,
            template: item?.template?.name,
          })
        })
      }
      return rows
    }, [])

    const getClickedRow = useCallback((data: object): void => {
      if (!isObjectEmpty(data)) {
        setClickedRowData(data)
        setOpenClickedRowModal(true)
      }
    }, [])

    const closeSideModal = () => {
      launchAssessDispatcher && launchAssessDispatcher({})
      setOpenClickedRowModal(false)
    }

    const getShowApproveToast = (val: any) => {
      setShowApproveToast(val)
      fetchInitialGridList(pageToStart, pageSize)
    }

    const handleModalClose = () => {
      setOpenClickedRowModal(false)
      navigate('/vrm/assessments-list')
    }

    return (
      <div className={styles.container}>
        <Container maxWidth="xl">
          <div className={styles.loadingArea}>
            <WppBanner id="banner" type="information" show={isToShowBanner}>
              Unable to fetch information. Please refresh or try after some time.
            </WppBanner>
            <WppBanner id="permission-banner" type="information" show={showPermissionBanner}>
              You don't have enough permission to view.
            </WppBanner>
            {showApproveToast && (
              <WppToast className={styles.toast} message="Assessment reviewed successfully" type="success" />
            )}
          </div>
          <Grid container spacing={{ xs: 2, md: 3, sm: 3 }} columns={{ xs: 4, sm: 8, md: 12 }} rowSpacing={10}>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              style={{ paddingTop: '12rem', marginBottom: '5rem' }}
              className="dataGridAssesmnet"
            >
              {isLoading ? (
                <DataGridOfflineComp />
              ) : (
                <>
                  {!showPermissionBanner && selectedSideOrg && (
                    <DataGrid
                      apiEndpoint={baseApiUrl + '/search'}
                      headerDetails={ASSES_GRID_HEADER_DETAILS}
                      apiMethod="POST"
                      pageSize={25}
                      onRowClickedHandler={getClickedRow}
                      isClearFilters={true}
                    />
                  )}
                </>
              )}
            </Grid>
          </Grid>
          {openClickedRowModal && (
            <RowDetails
              open={openClickedRowModal}
              clickedRowData={clickedRowData}
              showApproveToast={getShowApproveToast}
              closeSideModal={closeSideModal}
              onClose={handleModalClose}
            />
          )}
        </Container>
      </div>
    )
  }
)

export default connect(mapStateToProps, mapDispatchToProps)(AssessmentList)
