import React, { useEffect, useState } from 'react'

import Badge, { BadgeProps } from '@mui/material/Badge'
import { styled } from '@mui/material/styles'
import {
  WppBanner,
  WppCard,
  WppDivider,
  WppIconApp,
  WppIconLockOff,
  WppIconLockOn,
  WppIconPending,
  WppProgressIndicator,
  WppSpinner,
  WppTag
} from '@wppopen/components-library-react'
import { useOs } from '@wppopen/react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { cardList } from './CardList'
import styles from './LandingPage.module.scss'
import WelcomeMessage from './WelcomeMessage'
import { USER_ROLE } from '../../helper/constants'
import { convertToTitleCase, getLocalDate } from '../../helper/Helper'
import serviceURL from '../../helper/serviceURL'
import TagStatusVariantIndicator from '../../helper/TagStatusVariantIndicator'
import useAxiosInterceptors from '../../hooks/useAxiosInterceptors'

export const LandingPage = () => {
  const userRoles = useSelector((state: any) => state.getOrgByUserRed)
  const navigate = useNavigate()
  const [cardMenu, setCardMenu] = useState<any>()
  const [pendingActions, setPendingActions] = useState([])
  const [showSpinner, setShowSpinner] = useState(false)
  const { axiosInstance } = useAxiosInterceptors()
  const {
    osApi: { getAccessToken }
  } = useOs()
  const handleCard = (path: string) => {
    navigate(path)
  }

  useEffect(() => {
    if (userRoles.data) {
      setCardMenu(cardList(userRoles.data))
      fetchPendingActions()
    }
  }, [userRoles])

  const fetchPendingActions = () => {
    setShowSpinner(true)
    const apiUrl = serviceURL.pgpBaseAPI + '/api/pm/pending-review'
    const headers = {
      accept: '*/*',
      Authorization: 'Bearer ' + getAccessToken()
    }
    axiosInstance
      .get(apiUrl, {
        headers: headers
      })
      .then((res: any) => {
        if (res?.data) {
          setPendingActions(res.data)
          setShowSpinner(false)
        }
      })
      .catch(error => {
        console.log(error)
        setShowSpinner(false)
      })
  }

  const isUserAuthorized = (assignedRoles: any, allowedRoles: any) => {
    if (assignedRoles) {
      const isUserAuth = assignedRoles.some((item: any) => allowedRoles.includes(item.name))
      return isUserAuth
    }
    return false
  }

  const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
    '& .MuiBadge-badge': {
      right: -12,
      top: 0,
      border: `0 solid ${theme.palette.background.paper}`,
      padding: '0 4px',
      fontSize: '1.6rem',
      borderRadius: '50%',
      width: '2.5rem',
      height: '2.5rem'
    }
  }))

  const handleCardClick = () => {
    navigate('/vrm/assessments-list')
  }

  if (userRoles.loading) {
    return <WppProgressIndicator className={styles.customLoader} variant="bar" />
  } else if (userRoles.data) {
    return (
      <>
        <div className={styles.container}>
          <WelcomeMessage userData={userRoles?.data} agency={userRoles?.context?.agency} />
          <div className={styles.cardSection}>
            {cardMenu &&
              cardMenu.map((card: any, idx: number) => (
                <div
                  key={`card-sec-${idx}`}
                  className={`${
                    userRoles?.data?.organizationId === -1 || card.disable
                      ? styles.notAuthorizeCard + ' ' + styles.cardSectionChild
                      : styles.cardSectionChild
                  }`}
                >
                  <WppCard
                    key={`card-${idx}`}
                    className={styles.cardStyle}
                    size="2xl"
                    disabled={userRoles?.data?.organizationId === -1 || card.disable}
                    interactive={!card.disable}
                    onWppClick={() => handleCard(card.navigation)}
                  >
                    <div key={`action-${idx}`} className={styles.authorize}>
                      <div className={styles.apps}>
                        <WppTag label={`${card.apps} Apps`} categoricalColorIndex={8}>
                          <WppIconApp slot="icon-start" />
                        </WppTag>
                      </div>
                      <div className={styles.authorizeLabel}>
                        {userRoles?.data?.organizationId === -1 || card.disable ? (
                          <WppTag label="Not Authorised" variant="negative">
                            <WppIconLockOff slot="icon-start" />
                          </WppTag>
                        ) : (
                          <WppTag label="Authorised" variant="positive">
                            <WppIconLockOn slot="icon-start" />
                          </WppTag>
                        )}
                      </div>
                    </div>
                    <div key={`name-${idx}`} className={styles.header}>
                      {card.name}
                    </div>
                    <WppDivider />
                    <div key={`desc-${idx}`} className={styles.description}>
                      {card.description}
                    </div>
                  </WppCard>
                </div>
              ))}
          </div>
          {userRoles?.data?.organizationId !== -1 &&
          userRoles?.data?.groups &&
          isUserAuthorized(userRoles?.data?.groups, [USER_ROLE.DPM]) &&
          userRoles?.data?.groups?.length !== 0 ? (
            <div className={styles.pendingSection}>
              <div className={styles.pendingHeader}>
                <p className={styles.pendingHeaderText}>Pending actions</p>
                <StyledBadge badgeContent={pendingActions?.length} color="info">
                  <WppIconPending size="m" color="black" />
                </StyledBadge>
              </div>
              {showSpinner ? (
                <WppSpinner size="s" color="black" />
              ) : (
                <div className={styles.pendingItemSection}>
                  {pendingActions?.length === 0 ? (
                    <p>No pending actions</p>
                  ) : (
                    <>
                      {pendingActions?.map((item: any, idx: number) => (
                        <div className={styles.pendingItems} key={idx}>
                          <WppIconPending size="m" color="black" />
                          <div className={styles.pendingList} onClick={handleCardClick}>
                            <p className={styles.pendingItemsTextFirst}>
                              <span className={styles.key}>Assessment name: </span>
                              {convertToTitleCase(item?.name)}
                            </p>
                            <p className={styles.pendingItemsText}>
                              <span className={styles.key}>Status: </span>
                              <TagStatusVariantIndicator params={{ value: item?.status }} />
                            </p>
                            <p className={styles.pendingItemsText}>
                              <span className={styles.key}>Created at: </span>
                              {item?.createdAt && getLocalDate(item?.createdAt)}
                            </p>
                          </div>
                        </div>
                      ))}
                    </>
                  )}
                </div>
              )}
            </div>
          ) : null}
        </div>
      </>
    )
  } else if (userRoles.error) {
    return (
      <WppBanner id="banner" type="information" show={true}>
        Unable to fetch information. Please refresh or try after some time.
      </WppBanner>
    )
  }
  return null
}

export default LandingPage
