import { ChartConfiguration } from 'chart.js/auto'

import { WPP_CATEGORICAL_COLORS } from './colors'
import { INVENTORY, GROUPS } from './constants'
import { rowDataProps } from './interface'

const isObjectEmpty = (objectName: object) => {
  return JSON.stringify(objectName) === '{}'
}

export const delayCall = async (callback: Function, delay = 1000) =>
  await new Promise(resolve =>
    setTimeout(() => {
      resolve(callback())
    }, delay)
  )

export const parseQuestionBankIds = (item: string | object) => {
  const parseItem: any = typeof item === 'string' && item.split('and')
  let parseObj: any = {}
  for (let itm = 0; itm < parseItem.length; itm++) {
    switch (itm) {
      case 0:
        parseObj['assessId'] = parseItem[itm].trim()
        break
      case 1:
        parseObj['questionId'] = parseItem[itm].trim()
        break
      case 2:
        parseObj['categoryId'] = parseItem[itm].trim()
        break
      case 3:
        parseObj['responseId'] = parseItem[itm].trim()
        break
      default:
        parseObj['assessId'] = parseItem[itm].trim()
    }
  }
  return parseObj
}

export const getTime = (apiTime: string) => {
  let resTime = ''
  const backendLocalTime = new Date(apiTime)
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ]
  resTime = ` ${
    monthNames[backendLocalTime.getMonth()]
  } ${backendLocalTime.getDate()}, ${backendLocalTime.getFullYear()}`
  return resTime
}
export const prepareLinkAccordData = (data: { linkedInventories: object[] }) => {
  if (!data) {
    return
  }
  let accordArr: any[] = []
  for (const [key, value] of Object.entries<any>(data.linkedInventories)) {
    if (
      (key === INVENTORY.PA_TO_DA && value.length) ||
      (key === INVENTORY.PA_TO_LE && value.length) ||
      (key === INVENTORY.PA_TO_PA && value.length) ||
      (key === INVENTORY.PA_TO_VE && value.length) ||
      (key === INVENTORY.DA_TO_DA && value.length) ||
      (key === INVENTORY.DA_TO_LE && value.length) ||
      (key === INVENTORY.DA_TO_PA && value.length) ||
      (key === INVENTORY.DA_TO_VE && value.length) ||
      (key === INVENTORY.VE_TO_DA && value.length) ||
      (key === INVENTORY.VE_TO_LE && value.length) ||
      (key === INVENTORY.VE_TO_PA && value.length) ||
      (key === INVENTORY.VE_TO_VE && value.length) ||
      (key === INVENTORY.LE_TO_DA && value.length) ||
      (key === INVENTORY.LE_TO_LE && value.length) ||
      (key === INVENTORY.LE_TO_PA && value.length) ||
      (key === INVENTORY.LE_TO_VE && value.length)
    ) {
      accordArr.push({
        accordName:
          (key === INVENTORY.PA_TO_LE && INVENTORY.LINK_LE) ||
          (key === INVENTORY.DA_TO_LE && INVENTORY.LINK_LE) ||
          (key === INVENTORY.VE_TO_LE && INVENTORY.LINK_LE) ||
          (key === INVENTORY.LE_TO_LE && INVENTORY.LINK_LE) ||
          (key === INVENTORY.PA_TO_VE && INVENTORY.LINK_VE) ||
          (key === INVENTORY.DA_TO_VE && INVENTORY.LINK_VE) ||
          (key === INVENTORY.VE_TO_VE && INVENTORY.LINK_VE) ||
          (key === INVENTORY.LE_TO_VE && INVENTORY.LINK_VE) ||
          (key === INVENTORY.PA_TO_DA && INVENTORY.LINK_DA) ||
          (key === INVENTORY.DA_TO_DA && INVENTORY.LINK_DA) ||
          (key === INVENTORY.VE_TO_DA && INVENTORY.LINK_DA) ||
          (key === INVENTORY.LE_TO_DA && INVENTORY.LINK_DA) ||
          (key === INVENTORY.PA_TO_PA && INVENTORY.LINK_PA) ||
          (key === INVENTORY.DA_TO_PA && INVENTORY.LINK_PA) ||
          (key === INVENTORY.VE_TO_PA && INVENTORY.LINK_PA) ||
          (key === INVENTORY.LE_TO_PA && INVENTORY.LINK_PA),
        childAccord: value,
        type:
          (key === INVENTORY.PA_TO_LE && INVENTORY.TYPE_LE) ||
          (key === INVENTORY.DA_TO_LE && INVENTORY.TYPE_LE) ||
          (key === INVENTORY.VE_TO_LE && INVENTORY.TYPE_LE) ||
          (key === INVENTORY.LE_TO_LE && INVENTORY.TYPE_LE) ||
          (key === INVENTORY.PA_TO_VE && INVENTORY.TYPE_VE) ||
          (key === INVENTORY.DA_TO_VE && INVENTORY.TYPE_VE) ||
          (key === INVENTORY.VE_TO_VE && INVENTORY.TYPE_VE) ||
          (key === INVENTORY.LE_TO_VE && INVENTORY.TYPE_VE) ||
          (key === INVENTORY.PA_TO_DA && INVENTORY.TYPE_DA) ||
          (key === INVENTORY.DA_TO_DA && INVENTORY.TYPE_DA) ||
          (key === INVENTORY.VE_TO_DA && INVENTORY.TYPE_DA) ||
          (key === INVENTORY.LE_TO_DA && INVENTORY.TYPE_DA) ||
          (key === INVENTORY.PA_TO_PA && INVENTORY.TYPE_PA) ||
          (key === INVENTORY.DA_TO_PA && INVENTORY.TYPE_PA) ||
          (key === INVENTORY.VE_TO_PA && INVENTORY.TYPE_PA) ||
          (key === INVENTORY.LE_TO_PA && INVENTORY.TYPE_PA)
      })
    }
  }
  return accordArr
}
export const findQuestions = (questionBank: { questionByCategories: object }, catId: string) => {
  for (const [key, value] of Object.entries(questionBank.questionByCategories)) {
    if (key === catId && value.length) {
      return value
    }
  }
  return []
}
export const prepareAssessmentDataHelper = (
  data: any,
  usergroups: any[],
  selectedSideOrg: any,
  getOrgByUserRed: any
) => {
  let tempQn = []
  let riskQnCount = 0
  let _assessment = {
    assessmentName: data.name,
    assessmentId: data.id,
    inventoryName: data.inventoryName,
    details: data.categories.map((category: any) => {
      let queList = findQuestions(data, category.id)
      if (queList.length) {
        tempQn.push(queList)
        return {
          categoryName: category.name,
          categoryId: category.id,
          questionAnswers:
            Array.isArray(queList) &&
            queList.map((que: any) => {
              if (que.response?.risky) {
                riskQnCount++
              }
              if (que.question['description'] && que.question['description'] !== 'Review Comments') {
                return {
                  question: que.question,
                  response: que.response,
                  comments: que.comments,
                  totalComments: que.totalComments,
                  riskDetails: que.riskDetails,
                  risky: que.response?.risky,
                  attachments: que?.attachments
                }
              } else {
                return {}
              }
            }),
          riskQnsCount: riskQnCount
        }
      }
    }),
    isAllQnNonEmpty: tempQn.length > 0
  }
  let enableComment = usergroups?.filter(m => m.name === GROUPS.DPM).length > 0
  if (enableComment && selectedSideOrg?.id === getOrgByUserRed?.organizationId) {
    _assessment.details.push({ categoryName: 'Data Privacy Team Only', id: 60, groupm: true, questionAnswers: [] })
  }
  return _assessment
}

export const prepareRowData = (rowData: []) => {
  let rows: any = []
  if (rowData) {
    rowData.forEach((item: rowDataProps) => {
      rows.push({
        id: item.id,
        name: item.name,
        createdBy: item.createdBy,
        createdAt: getLocalDate(item.createdAt),
        status: item.status,
        organization: item.organization?.name,
        organizationId: item.organization?.id,
        market: item.market?.name,
        region: item.region?.name,
        agency: item.agency?.name,
        inventoryServiceType: item.inventoryServiceType?.serviceType,
        modifiedAt: getLocalDate(item.modifiedAt),
        modifiedBy: item.modifiedBy,
        paInventory: item.paInventory,
        vendorInventory: item.vendorInventory,
        description: item.description,
        masterInventory: item.masterInventory ? 'Yes' : 'No',
        legalEntityInventory: item.legalEntityInventory,
        daInventory: item.daInventory,
        country: item?.country?.name,
        industry: item?.industry?.name,
        serviceTypes: item?.serviceTypes,
        dataSubjectLocations: item?.dataSubjectLocations
      })
    })
  }
  return rows
}

export const convertToTitleCase = (str: string) => {
  return str
    ?.toLowerCase()
    ?.split('_')
    ?.map(word => word.charAt(0)?.toUpperCase() + word.slice(1))
    ?.join(' ')
}

export default isObjectEmpty

export const generateRandomColor = () => {
  const usedIndexes: [number] = [0]
  let randomIndex = -1
  const maxRepeat = 2
  do {
    randomIndex = Math.floor(Math.random() * WPP_CATEGORICAL_COLORS.length)
  } while (usedIndexes[randomIndex] >= maxRepeat)

  usedIndexes[randomIndex] = (usedIndexes[randomIndex] || 0) + 1
  return WPP_CATEGORICAL_COLORS[randomIndex]
}
export const commonOptions: ChartConfiguration<'doughnut'>['options'] = {
  plugins: {
    legend: {
      display: true,
      position: 'right',
      align: 'start',
      maxHeight: 10,
      labels: {
        font: {
          size: 12,
          family: 'Inter, Helvetica Neu, sans-serif'
        },
        padding: 10,
        usePointStyle: true,
        color: 'black'
      }
    }
  }
}

export const getLocalDate = (date: any, isTime = false) => {
  if (!date) {
    return null
  }

  let utcDate = new Date(date)

  let localDay = String(utcDate.getDate()).padStart(2, '0')
  let localMonth = utcDate.toLocaleString('default', { month: 'short' })
  let localYear = utcDate.getFullYear()
  if (isTime) {
    let localHours = utcDate.getHours()
    let localMinutes = String(utcDate.getMinutes()).padStart(2, '0')
    let localSeconds = String(utcDate.getSeconds()).padStart(2, '0')

    let ampm = localHours >= 12 ? 'PM' : 'AM'
    localHours = localHours % 12
    localHours = localHours || 12
    return `${localDay}-${localMonth}-${localYear} ${String(localHours).padStart(
      2,
      '0'
    )}:${localMinutes}:${localSeconds} ${ampm}`
  }

  return `${localDay}-${localMonth}-${localYear}`
}

export const uploadFileName = (filename: string) => {
  const firstUnderscoreIndex = filename?.indexOf('_')
  const nameAfterFirstUnderscore = filename?.substring(firstUnderscoreIndex + 1)
  return nameAfterFirstUnderscore
}
