import React, { forwardRef, memo, useEffect, useImperativeHandle, useState, ChangeEvent } from 'react'

import _ from 'lodash'

import TextArea from 'components/textArea/TextArea'

import styles from './QueAnsAssessment.module.scss'
import { parseQuestionBankIds } from '../../helper/Helper'

interface comment {
  id: string | object
  comment: string
}
let initialCommentState: comment = {
  id: {},
  comment: ''
}
const CommentSection = memo(
  forwardRef((props: any, ref) => {
    const [commentRes, setCommentRes] = useState<comment>(initialCommentState)
    useImperativeHandle(
      ref,
      () => {
        return {
          commentRes,
          setCommentRes
        }
      },
      [commentRes, setCommentRes]
    )
    const commentHandler = () => {
      if (!_.isEqual(commentRes, initialCommentState)) {
        const parseObjRes = parseQuestionBankIds(commentRes.id)
        props.executeComment && props.executeComment({ id: parseObjRes, comment: commentRes.comment })
      }
    }

    const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
      setCommentRes({ id: props.commentKey, comment: e.target.value })
    }

    useEffect(() => {
      commentHandler()
    }, [commentRes])

    return (
      <div className={styles.feedbackContainer}>
        <p>Feedback:</p>
        <TextArea
          className={styles.reviewContainerCommentItem}
          value={commentRes['comment']}
          onChange={e => handleChange(e)}
          placeholder="Enter Feedback"
        />
      </div>
    )
  })
)
export default CommentSection
