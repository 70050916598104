import React, { useEffect, useRef, useState } from 'react'

import { Grid, Container } from '@mui/material'
import { WppBanner, WppProgressIndicator } from '@wppopen/components-library-react'
import { Chart, registerables } from 'chart.js'
import { useSelector } from 'react-redux'

import { buildAssessmentChart } from './charts'
import styles from './PIADashboard.module.scss'
import DashboardCards from '../../../components/dashboardCards/DashboardCards'
import { StatusObject } from '../../../helper/interface'
import serviceURL from '../../../helper/serviceURL'
import useAxiosInterceptors from '../../../hooks/useAxiosInterceptors'

interface AssessmentStatus {
  approved: number
  expired: number
  rejected: number
  review: number
  waiting: number
  risky: number
}

export const PIADashboard = () => {
  Chart.register(...registerables)
  const canvasRefAssessment = useRef(null)
  const selectedSideOrg = useSelector((state: any) => state.selectedSideNavOrgRed.data)
  const [isLoading, setIsLoading] = useState(false)
  const [isToShowBanner, setIsToShowBanner] = useState(false)
  const [approved, setApproved] = useState(0)
  const [expired, setExpired] = useState(0)
  const [rejected, setRejected] = useState(0)
  const [waiting, setWaiting] = useState(0)
  const [review, setReview] = useState(0)
  const [risky, setRisky] = useState(0)
  const { axiosInstance } = useAxiosInterceptors()

  useEffect(() => {
    fetchData()
  }, [selectedSideOrg])

  const fetchData = () => {
    const apiUrl = serviceURL.pgpBaseAPI + '/api/report/privacy-review?orgId=' + selectedSideOrg.id
    setIsLoading(true)
    axiosInstance
      .get(apiUrl)
      .then((res: any) => {
        createAssessmentStatusChart(res?.data)
        setIsLoading(false)
        setIsToShowBanner(false)
      })
      .catch(error => {
        console.log(error)
        setIsLoading(false)
        setIsToShowBanner(true)
      })
  }

  const createAssessmentStatusChart = (response: StatusObject) => {
    let statusCountDA = []
    let statusCountPA = []
    let statusCountVE = []
    let approved = 0
    let expired = 0
    let rejected = 0
    let waiting = 0
    let review = 0
    let risky = 0
    if (response?.assessmentByStatus) {
      for (const [parentKey, values] of Object.entries<AssessmentStatus>(response?.assessmentByStatus)) {
        for (const [key, value] of Object.entries(values)) {
          switch (parentKey) {
            case 'PA-assessment':
              statusCountPA.push(value)
              break
            case 'DA-assessment':
              statusCountDA.push(value)
              break
            case 'VE-assessment':
              statusCountVE.push(value)
              break
          }
          switch (key) {
            case 'approved':
              approved += value
              break
            case 'expired':
              expired += value
              break
            case 'waiting':
              waiting += value
              break
            case 'rejected':
              rejected += value
              break
            case 'review':
              review += value
              break
            case 'riskcalculation':
              risky += value
              break
          }
        }
      }
      setApproved(isNaN(approved) ? 0 : approved)
      setExpired(isNaN(expired) ? 0 : expired)
      setRejected(isNaN(rejected) ? 0 : rejected)
      setWaiting(isNaN(waiting) ? 0 : waiting)
      setReview(isNaN(review) ? 0 : review)
      setRisky(isNaN(risky) ? 0 : risky)
    }
    buildAssessmentChart(canvasRefAssessment, statusCountPA, statusCountDA, statusCountVE)
  }

  return (
    <Container maxWidth="xl">
      {isLoading && <WppProgressIndicator variant="bar" className={styles.customLoader} />}
      <WppBanner id="banner" type="information" show={isToShowBanner}>
        Unable to fetch information. Please refresh or try after some time.
      </WppBanner>
      <Grid container spacing={3} marginTop="5rem" justifyContent="center">
        <Grid item xs={12} md={3} lg={3}>
          <DashboardCards
            title="Total Assessments"
            count={approved + expired + rejected + review + waiting + risky}
            color="info"
            icon="ant-design:apple-filled"
            bgcolor="white"
          />
        </Grid>
        <Grid item xs={12} md={3} lg={3}>
          <DashboardCards
            title="Assessments Approved"
            count={approved}
            color="info"
            icon="ant-design:apple-filled"
            bgcolor="white"
          />
        </Grid>
        <Grid item xs={12} md={3} lg={3}>
          <DashboardCards
            title="Assessments Expired"
            count={expired}
            color="info"
            icon="ant-design:apple-filled"
            bgcolor="white"
          />
        </Grid>
        <Grid item xs={12} md={3} lg={3}>
          <DashboardCards
            title="Assessments Rejected"
            count={rejected}
            color="info"
            icon="ant-design:apple-filled"
            bgcolor="white"
          />
        </Grid>
        <Grid item xs={12} md={3} lg={3}>
          <DashboardCards
            title="Assessments Response"
            count={waiting}
            color="info"
            icon="ant-design:apple-filled"
            bgcolor="white"
          />
        </Grid>
        <Grid item xs={12} md={3} lg={3}>
          <DashboardCards
            title="Assessments Review"
            count={review}
            color="info"
            icon="ant-design:apple-filled"
            bgcolor="white"
          />
        </Grid>
        <Grid item xs={12} md={3} lg={3}>
          <DashboardCards
            title="Assessments Risky"
            count={risky}
            color="info"
            icon="ant-design:apple-filled"
            bgcolor="white"
          />
        </Grid>
        <Grid item xs={12} md={10} lg={10} marginTop="5rem">
          <div className={styles.sectionDoughnut}>
            <p>Assessments Status</p>
            <div ref={canvasRefAssessment} style={{ width: '100%', height: '400px' }} />
          </div>
        </Grid>
      </Grid>
    </Container>
  )
}

export default PIADashboard
