import {
  getAgencyList,
  getInvList,
  getMarketList,
  getRegList,
  selectedAgencyDispatcher,
  selectedInvDispatcher,
  selectedMarketDispatcher,
  selectedRegionDispatcher,
  selectedTypeDispatcher,
  selectedTypeOfServiceDispatcher,
  getServiceTypeList
} from './step1/action'
import { getTemplates, selectedTemplateDispatcher } from './step2/action'
import {
  assessNameDispatcher,
  getAssesser,
  getRespondent,
  respondentNoteDispatcher,
  selectedAssesserDispatcher,
  selectedDaysDispatcher,
  selectedRespondentDispatcher
} from './step3/action'
import { setTriggerReload } from 'containers/tprmModule/vendorsList/rowDetails/action'

export const mapStateToProps = (state: any) => {
  return {
    selectedType: state.caSelectedTypeReducer.data,
    selectedInventory: state.caSelectedInvReducer.data,
    selectedRegion: state.caSelectedRegReducer.data,
    selectedMarket: state.caSelectedMarketReducer.data,
    selectedAgency: state.caSelectedAgencyReducer.data,
    selectedTemplate: state.caSelectedTemplateReducer.data,
    isLaunchAssess: state.launchAssessRed.data,
    selectedTypeOfService: state.caTypeOfServiceReducer.data
  }
}

export const mapDispatchToProps = (dispatch: any) => ({
  getInvList: (invList: any) => dispatch(getInvList(invList)),
  getRegList: (regList: any) => dispatch(getRegList(regList)),
  getMarketList: (marketList: any) => dispatch(getMarketList(marketList)),
  getAgencyList: (agencyList: any) => dispatch(getAgencyList(agencyList)),
  getTemplates: (templateList: any) => dispatch(getTemplates(templateList)),
  getServiceTypeList: (serviceTypeList: any) => dispatch(getServiceTypeList(serviceTypeList)),
  getRespondent: (respondentList: any) => dispatch(getRespondent(respondentList)),
  getAssesser: (assesserList: any) => dispatch(getAssesser(assesserList)),
  selectedTypeDispatcher: (type: { id: number; name: string }) => dispatch(selectedTypeDispatcher(type)),
  selectedInvDispatcher: (inv: { id: number; name: string }) => dispatch(selectedInvDispatcher(inv)),
  selectedRegionDispatcher: (reg: { id: number; name: string }) => dispatch(selectedRegionDispatcher(reg)),
  selectedMarketDispatcher: (market: { id: number; name: string }) => dispatch(selectedMarketDispatcher(market)),
  selectedAgencyDispatcher: (agency: { id: number; name: string }) => dispatch(selectedAgencyDispatcher(agency)),
  selectedTemplateDispatcher: (template: { id: number; name: string }) =>
    dispatch(selectedTemplateDispatcher(template)),
  assessNameDispatcher: (name: string) => dispatch(assessNameDispatcher(name)),
  selectedRespondentDispatcher: (respondent: Array<{ id: number; email: string }>) =>
    dispatch(selectedRespondentDispatcher(respondent)),
  selectedDaysDispatcher: (days: number) => dispatch(selectedDaysDispatcher(days)),
  respondentNoteDispatcher: (note: string) => dispatch(respondentNoteDispatcher(note)),
  selectedAssesserDispatcher: (assesser: { id: number; email: string }) =>
    dispatch(selectedAssesserDispatcher(assesser)),
  selectedTypeOfServiceDispatcher: (typeOfService: { id: number; serviceType: string }) =>
    dispatch(selectedTypeOfServiceDispatcher(typeOfService)),
  setTriggerReloadDispatcher: (value: boolean) => dispatch(setTriggerReload(value)),
})
