import React, { memo, useCallback, useEffect, useLayoutEffect, useState } from 'react'

import { Container, Grid } from '@mui/material'
import {
  WppBanner,
  WppProgressIndicator,
  WppSideModal,
  WppTypography,
  WppTag,
  WppButton,
  WppIconAdd,
  WppIconClose,
  WppActionButton
} from '@wppopen/components-library-react'
import { useOs } from '@wppopen/react'
import _ from 'lodash'
import { connect } from 'react-redux'

import appConfig from 'app.config'
import DataGridOfflineComp from 'components/dataGrid/DataGridOfflineComp'

import { clearAssessmentByIdDispatcher, getAssessmentByIdDispatcher, getInventoryByAssessIdDispatcher } from './action'
import styles from './RowDetails.module.scss'
import AssessmentSummary from '../../../../components/assessmentSummary/AssessmentSummary'
import AssessmentPdf from '../../../../components/pdf/assessmentPdf'
import QuesAnsAssessment from '../../../../components/queAnsAsssessment/QuesAnsAssessment'
import { ASSESS_PROGRESS_STATUS, STATUS, USER_ROLE } from '../../../../helper/constants'
import { getLocalDate, prepareAssessmentDataHelper, prepareLinkAccordData } from '../../../../helper/Helper'
import { progressStatus } from '../../../../helper/interface'
import TagStatusCategoryIndicator from '../../../../helper/TagStatusCategoryIndicator'
import RelaunchAssessment from '../createAssessment/RelaunchAssessment'
import { AccordItem, AssessAccordItem } from '../interface'

const mapDispatchToProps = (dispatch: any) => ({
  getAssessmentByIdDispatcher: (id: string, head: object) => dispatch(getAssessmentByIdDispatcher(id, head)),
  clearAssessmentByIdDispatcher: (invAssetRes: object) => dispatch(clearAssessmentByIdDispatcher(invAssetRes)),
  getInventoryByAssessIdDispatcher: (type: string, id: string, head: object, orgId: number) =>
    dispatch(getInventoryByAssessIdDispatcher(type, id, head, orgId))
})

const mapStateToProps = (state: any) => {
  return {
    base64Email: state.storeBase64EmailReducer.data,
    assessRowDetails: state.assessmentRowDetailsRed.data,
    inventoryByAssessDetails: state.inventoryDetailsViaAssessRed.data,
    selectedSideOrg: state.selectedSideNavOrgRed.data,
    getOrgByUserRed: state.getOrgByUserRed.data
  }
}

export interface RowDetailsModalProps {
  clickedRowData?: {
    id: string
    name: string
    description?: string
    inventoryId?: string
    inventoryType?: any
    categories?: any
    status?: boolean
  }
  open: boolean
  onClose: () => void
  getAssessmentByIdDispatcher?: (id: string, head: object, orgId: number) => Promise<any>
  getInventoryByAssessIdDispatcher?: (type: string, id: string, head: object, orgId: number) => Promise<any>
  clearAssessmentByIdDispatcher?: (invAssetRes: object) => Promise<any>
  selectedSideOrg?: {
    id: number
    name: string
  }
  getOrgByUserRed: any
  base64Email?: string
  assessRowDetails?: any
  inventoryDetails?: any
  showApproveToast?: (val: boolean) => void
  closeSideModal?: () => void
}

const initialAccordArrState: AccordItem[] = []

const initialStateChildRow = {
  id: '',
  name: '',
  inventoryAttributes: { categories: [] }
}
const RowDetails = memo(
  ({
    open,
    onClose,
    clickedRowData,
    getAssessmentByIdDispatcher,
    getInventoryByAssessIdDispatcher,
    clearAssessmentByIdDispatcher,
    base64Email,
    assessRowDetails,
    selectedSideOrg,
    getOrgByUserRed,
    showApproveToast,
    closeSideModal
  }: RowDetailsModalProps) => {
    const [isLoading, setIsLoading] = useState(false)
    const [isToShowBanner, setIsToShowBanner] = useState(false)
    const [isToShowInvBanner, setIsToShowInvBanner] = useState(false)
    const [inventoryByAssessDetails, setInventoryByAssessDetails] = useState(initialStateChildRow)
    const [currentTab, setCurrentTab] = useState('AssessmentDetails')
    const [accordAssessObj, setAccordAssessObj] = useState<AssessAccordItem>({
      assessmentId: '1',
      assessmentName: '',
      details: [],
      inventoryName: null,
      isAllQnNonEmpty: false
    })
    const [accordLinkArr, setLinkAccordArr] = useState<AccordItem[]>(initialAccordArrState)
    const [invAssetRes, setInvAssetRes] = useState({ asessDetails: null, invDetails: null, hasError: false })
    const {
      osApi: { getAccessToken }
    } = useOs()
    const headers = {
      accept: '*/*',
      Authorization: 'Bearer ' + getAccessToken()
    }
    const [progressValue, setProgressValue] = useState(0)
    const [reviewerCommentLoaded, setReviewerCommentLoaded] = useState(null)
    const [relaunchModal, setRelaunchModal] = useState(false)
    const progressCalculator = (list: progressStatus[], val: string) => {
      for (const [, listVal] of Object.entries(list)) {
        if (listVal['name'] === val) {
          return listVal['value']
        }
      }
      return 0
    }
    const executeCallback = useCallback(
      (val: any) => {
        setReviewerCommentLoaded(val)
      },
      [reviewerCommentLoaded]
    )
    useEffect(() => {
      if (assessRowDetails && assessRowDetails.status) {
        setProgressValue(progressCalculator(ASSESS_PROGRESS_STATUS, assessRowDetails.status))
      }
    }, [assessRowDetails])

    useLayoutEffect(() => {
      fetchAssessmentById()
      return () => {
        clearAssessmentByIdDispatcher &&
          clearAssessmentByIdDispatcher(invAssetRes)
            .then(() => {
              console.log('clearing row details modal')
            })
            .catch(err => {
              console.log(err)
            })
      }
    }, [])

    const fetchAssessmentById = () => {
      if (clickedRowData && selectedSideOrg) {
        setIsLoading(true)
        getAssessmentByIdDispatcher &&
          getAssessmentByIdDispatcher(clickedRowData.id, headers, selectedSideOrg.id)
            .then(res => {
              setIsLoading(false)
              prepareAssessmentData(res)
            })
            .catch(err => {
              console.log(err)
              setIsLoading(false)
              setIsToShowBanner(true)
            })
      }
    }
    const fetchInventoryByAssessId = () => {
      if (clickedRowData && selectedSideOrg && assessRowDetails && assessRowDetails.inventoryId) {
        setIsLoading(true)
        getInventoryByAssessIdDispatcher &&
          getInventoryByAssessIdDispatcher(
            assessRowDetails.inventoryType.name,
            assessRowDetails.inventoryId,
            headers,
            selectedSideOrg.id
          )
            .then(res => {
              if (res) {
                console.log('result by inventory id>>>>', res)
                setInventoryByAssessDetails(res)
                setIsLoading(false)
                makeLinkAccordData(res)
              }
            })
            .catch(err => {
              console.log(err)
              setIsLoading(false)
              setIsToShowInvBanner(true)
            })
      }
    }

    const prepareAssessmentData = (data: any) => {
      const _assessment = prepareAssessmentDataHelper(data, getOrgByUserRed.groups, selectedSideOrg, getOrgByUserRed)
      setAccordAssessObj(_assessment)
    }
    const makeLinkAccordData = (data: { linkedInventories: object[] }) => {
      const resOfPrepareLinkAccordData: any = prepareLinkAccordData(data)
      setLinkAccordArr(resOfPrepareLinkAccordData)
    }

    useEffect(() => {
      if (
        currentTab &&
        currentTab === 'InventoryDetails' &&
        _.isEqual(inventoryByAssessDetails, initialStateChildRow)
      ) {
        fetchInventoryByAssessId()
      }
    }, [currentTab, inventoryByAssessDetails])

    const handleRelaunchAssessment = () => {
      setRelaunchModal(true)
    }

    const isEligibleForRelaunch = () => {
      if (appConfig.CUTOFF_DATE !== 'NA') {
        const launchDate = new Date(assessRowDetails?.createdAt)
        const cutOffDate = new Date(appConfig.CUTOFF_DATE)
        const isBefore = launchDate < cutOffDate
        return assessRowDetails?.status === STATUS.RESPONSE_PENDING && isBefore
      } else {
        return assessRowDetails?.status === STATUS.RESPONSE_PENDING
      }
    }

    const hasRequiredPermission = (permission: string[]) => {
      return getOrgByUserRed?.groups?.some((item: any) => permission.includes(item.name))
    }

    return (
      <>
        <WppSideModal open={open} size="2xl" onWppSideModalClose={onClose} className={styles.modalBox}>
          {isLoading ? (
            <div slot="body">
              <DataGridOfflineComp />
            </div>
          ) : (
            <>
              <h3 slot="header" className={styles.assessNameProgressContainer}>
                <span className={styles.header}>{clickedRowData && clickedRowData.name}</span>
                <div className={styles.buttonAction}>
                  {hasRequiredPermission([USER_ROLE.SAD, USER_ROLE.DPM]) && (
                    <AssessmentPdf data={assessRowDetails} isLoading={isLoading} />
                  )}
                  <WppActionButton onClick={onClose} variant="secondary" slot="actions">
                    <WppIconClose className={styles.close} />
                  </WppActionButton>
                </div>
              </h3>
              <div slot="body" style={{ marginTop: '1.5rem' }}>
                {assessRowDetails.status && (
                  <div className={styles.progressContainer}>
                    <div>
                      <div>Review process {progressValue * 100 + '%'}</div>
                      <progress className={styles.progressBar} value={progressValue} />
                    </div>
                    {isEligibleForRelaunch() && (
                      <div>
                        <WppButton
                          className={styles.customBtnSecondary}
                          variant="secondary"
                          size="s"
                          onClick={handleRelaunchAssessment}
                        >
                          <WppIconAdd slot="icon-start" className={styles.customIcon} />
                          Relaunch Assessment
                        </WppButton>
                      </div>
                    )}
                  </div>
                )}
                {isLoading && (
                  <div style={{ marginBottom: '1rem' }}>
                    <WppProgressIndicator className={styles.customLoader} variant="bar" isShowPercentage={false} />
                  </div>
                )}

                <div style={{ marginBottom: '1rem' }}>
                  <WppBanner id="banner" type="information" show={isToShowBanner} className={styles.banner}>
                    Unable to fetch information. Please refresh or try after some time.
                  </WppBanner>
                </div>
                {assessRowDetails && (
                  <Container maxWidth="xl">
                    <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }} rowSpacing={10}>
                      <Grid item xs={12} sm={12} md={12}>
                        <div className={styles.secInventoryDetailsTop}>
                          <div className={styles.headerContainer}>
                            <div className={styles.leftContainer}>
                              <div className={styles.headerBox}>
                                <span className={styles.key}>Agency: </span>
                                <span className={styles.value}>
                                  {assessRowDetails?.agency?.name ? (
                                    <WppTag
                                      label={assessRowDetails?.agency.name}
                                      className={styles.tag}
                                      maxLabelLength={40}
                                      tooltipConfig={{ placement: 'bottom' }}
                                    />
                                  ) : (
                                    ''
                                  )}
                                </span>
                              </div>
                              <div className={styles.headerBox}>
                                <span className={styles.key}>Market: </span>
                                <span className={styles.value}>
                                  {assessRowDetails?.agency?.market?.name ? (
                                    <WppTag
                                      label={assessRowDetails?.agency?.market?.name}
                                      className={styles.tag}
                                      maxLabelLength={40}
                                      tooltipConfig={{ placement: 'bottom' }}
                                    />
                                  ) : (
                                    ''
                                  )}
                                </span>
                              </div>
                              <div className={styles.headerBox}>
                                <span className={styles.key}>Template: </span>
                                <span className={styles.value}>
                                  {assessRowDetails?.template?.name ? (
                                    <WppTag
                                      label={assessRowDetails?.template?.name}
                                      className={styles.tag}
                                      maxLabelLength={40}
                                      tooltipConfig={{ placement: 'bottom' }}
                                    />
                                  ) : (
                                    ''
                                  )}
                                </span>
                              </div>
                              <div className={styles.headerBox}>
                                <span className={styles.key}>Description: </span>
                                <span className={styles.value}>
                                  {assessRowDetails && assessRowDetails.description ? (
                                    <WppTag
                                      label={assessRowDetails.description}
                                      className={styles.tag}
                                      maxLabelLength={40}
                                      tooltipConfig={{ placement: 'bottom' }}
                                    />
                                  ) : (
                                    ''
                                  )}
                                </span>
                              </div>
                              <div className={styles.headerBox}>
                                <span className={styles.key}>Status: </span>
                                {assessRowDetails.status && (
                                  <TagStatusCategoryIndicator
                                    params={{ value: assessRowDetails?.status }}
                                    className={styles.customTag}
                                  />
                                )}
                              </div>
                            </div>
                            <div className={styles.rightContainer}>

                            <div className={styles.headerBox}>
                                <span className={styles.key}>Respondents: </span>
                                {assessRowDetails?.respondents && assessRowDetails?.respondents.length ? (
                                  <span className={styles.respondedContainer}>
                                    <WppTag
                                      label={assessRowDetails?.respondents
                                        ?.map((m: { email: string }) => m?.email)
                                        .join(', ')}
                                      className={styles.tag}
                                      maxLabelLength={40}
                                      tooltipConfig={{ placement: 'bottom' }}
                                    />
                                  </span>
                                ) : (
                                  ''
                                )}
                              </div>
                              <div className={styles.headerBox}>
                                <span className={styles.key}>Created By: </span>
                                <span className={styles.value}>
                                  {assessRowDetails && assessRowDetails?.createdBy ? (
                                    <WppTag
                                      label={assessRowDetails?.createdBy}
                                      className={styles.tag}
                                      maxLabelLength={40}
                                      tooltipConfig={{ placement: 'bottom' }}
                                    />
                                  ) : (
                                    ''
                                  )}
                                </span>
                              </div>
                              <div className={styles.headerBox}>
                                <span className={styles.key}>Created At: </span>
                                <span>{assessRowDetails?.createdAt && getLocalDate(assessRowDetails?.createdAt)}</span>
                              </div>
                              <div className={styles.headerBox}>
                                <span className={styles.key}>Inventory Type: </span>
                                <span>{assessRowDetails && assessRowDetails.inventoryType?.businessName}</span>
                              </div>
                              <div className={styles.headerBox}>
                                <span className={styles.key}>Last Activity Date: </span>
                                <span>
                                  {assessRowDetails?.modifiedAt && getLocalDate(assessRowDetails?.modifiedAt)}
                                </span>
                              </div>
                            </div>
                          </div>
                          <Grid className={styles.flowChart} item xs={12} sm={12} md={12}>
                            <AssessmentSummary
                              assessRowDetails={assessRowDetails}
                              callBackDataLoaded={executeCallback}
                              assessmentRiskCountDetails={accordAssessObj}
                              base64Email={base64Email}
                              reviewerCommentLoaded={reviewerCommentLoaded}
                            />
                          </Grid>
                        </div>
                      </Grid>

                      <Grid item xs={12} sm={12} md={12} paddingTop="2rem !important" paddingBottom="5rem !important">
                        <div className={styles.tabSection}>
                          <WppTypography className={styles.tabContent} id="queAndAnswers">
                            {!isLoading && (
                              <QuesAnsAssessment
                                assessment={accordAssessObj}
                                showApproveToast={showApproveToast}
                                closeSideModal={closeSideModal}
                                prepareAssessmentData={prepareAssessmentData}
                              />
                            )}
                          </WppTypography>
                        </div>
                      </Grid>
                    </Grid>
                  </Container>
                )}
              </div>
            </>
          )}
        </WppSideModal>
        {relaunchModal && (
          <RelaunchAssessment relaunchModal={relaunchModal} onClose={onClose} assessRowDetails={assessRowDetails} />
        )}
      </>
    )
  }
)

export default connect(mapStateToProps, mapDispatchToProps)(RowDetails)
