import { Chart } from 'chart.js'

import { commonOptions } from '../../../helper/Helper'

export const buildVendorChart = (canvasRefVendor: any, statusLabels: string[], statusCount: number[]) => {
  const data = {
    labels: statusLabels,
    datasets: [
      {
        label: 'Vendors Status',
        data: statusCount,
        backgroundColor: ['#E0FAEA', '#C2F5D4', '#84EBAA', '#47E17F'],
        hoverOffset: 10
      }
    ]
  }

  const chartRef = canvasRefVendor.current && canvasRefVendor.current.getContext('2d')
  const existingChart = Chart.getChart(chartRef)
  if (existingChart) {
    existingChart.destroy()
  }
  if (statusCount.every((ele: any) => ele === 0)) {
    chartRef.canvas.height = 255
    chartRef.beginPath()
    chartRef.lineWidth = 32
    chartRef.strokeStyle = 'lightgrey'
    chartRef.arc(150, 115, 55, 0, 2 * Math.PI)
    chartRef.fillStyle = 'black'
    chartRef.textAlign = 'center'
    chartRef.font = '14px Inter, sans-serif'
    chartRef.fillText('No data', 150, 120)
    chartRef.stroke()
  } else {
    new Chart(chartRef, {
      type: 'doughnut',
      data: data,
      options: commonOptions
    })
  }
}
