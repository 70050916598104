import axios from 'axios'

import { GET_INITIAL_VENDOR_DATA, SET_CREATE_VENDOR_DATA, SET_TRIGGER_RELOAD } from './types'
import { serviceURL } from '../../../../helper/serviceURL'

export const getInitialData = (data: any) => ({
  type: GET_INITIAL_VENDOR_DATA,
  payload: data
})

export const getAssessmentData = (data: any) => ({
  type: GET_INITIAL_VENDOR_DATA,
  payload: data
})

export const setCreateVendorData = (data: any) => ({
  type: SET_CREATE_VENDOR_DATA,
  payload: data
})

export const setTriggerReload = (data: boolean) => ({
  type: SET_TRIGGER_RELOAD,
  payload: data
})

export const fetchAssessmentData = (type: string, id: string, header: object) => (dispatch: any) => {
  const assessmentDetails = serviceURL.pgpBaseAPI + '/api/inventory/' + id + '/assessments?page=0&size=5'
  return new Promise((resolve, reject) => {
    axios
      .get(assessmentDetails, {
        headers: header
      })
      .then((resp: any) => {
        dispatch(getAssessmentData(resp.data))
        resolve(resp.data)
      })
      .catch(error => reject(error))
  })
}

export const fetchInitialData = (type: string, id: string, header: object) => (dispatch: any) => {
  const vendorDetails = serviceURL.pgpBaseAPI + '/api/inventory/' + type + '/' + id
  return new Promise((resolve, reject) => {
    axios
      .get(vendorDetails, { headers: header })
      .then(vendorResponse => {
        const data = {
          vendorData: vendorResponse.data
        }
        dispatch(getInitialData(data))
        resolve(data)
      })
      .catch(error => {
        // Handle errors
        reject(error)
      })
  })
}
