import React, { useCallback, useMemo, useRef, useEffect, useState } from 'react'

import { WppButton, WppSpinner } from '@wppopen/components-library-react'
import { useOs } from '@wppopen/react'
import { useSelector } from 'react-redux'
import { ColDef, GridReadyEvent, IGetRowsParams } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import axios from 'axios'

import './DataGridCompModule.scss'
import { getLocalDate } from 'helper/Helper'
import { USER_ROLE } from 'helper/constants'

const Loading = () => {
  return (
    <React.Fragment>
      <WppSpinner size="m" color="black" />
      <div
        className="ag-overlay-loading-center"
        style={{ backgroundColor: 'transparent', height: '5%', boxShadow: 'unset', border: 0 }}
      />
    </React.Fragment>
  )
}

interface DataGridProps {
  apiEndpoint: string
  onRowClickedHandler?: (data: any) => void
  apiMethod?: 'GET' | 'POST'
  headerDetails: Array<any>
  pageSize?: number
  isClearFilters?: boolean
  isTriggerReload?: boolean
}

const DataGrid: React.FC<DataGridProps> = ({
  apiEndpoint,
  onRowClickedHandler,
  apiMethod = 'GET',
  headerDetails,
  pageSize = 5,
  isClearFilters = false,
  isTriggerReload,
}) => {
  const {
    osContext,
    osApi: { getAccessToken }
  } = useOs()
  const { agency } = osContext.userDetails
  const gridRef = useRef<AgGridReact>(null)
  const [gridApi, setGridApi] = useState<any>(null)
  const userData = useSelector((state: any) => state.getOrgByUserRed.data)

  const headers = useMemo(
    () => ({
      accept: '*/*',
      Authorization: `Bearer ${getAccessToken()}`,
      'Content-Type': 'application/json'
    }),
    [getAccessToken]
  )

  const defaultColDef = useMemo<ColDef>(
    () => ({
      resizable: true
    }),
    []
  )

  const fetchData = useCallback(
    async (page: number, sortModel: any, filterModel: any) => {
      if (gridRef.current) {
        gridRef.current.api.showLoadingOverlay()
      }

      const sortPart = sortModel.map((sort: any) => ({
        sort: sort.colId,
        order: sort.sort === 'asc' ? 'asc' : 'desc'
      }))

      const filterPart = Object.keys(filterModel).map(filterField => {
        const filter = filterModel[filterField]
        let filterObject: any = { name: filterField }
        if (filter.filterType === 'date') {
          const dateFrom = filter.dateFrom ? filter.dateFrom.split(' ')[0] : ''
          const dateTo = filter.dateTo ? filter.dateTo.split(' ')[0] : ''
          filterObject.value = `${dateFrom}:${dateTo}`
          filterObject.operator = 'BETWEEN'
        } else {
          filterObject.value = filter.filter
          filterObject.operator = filter.type === 'contains' ? 'LIKE' : filter.type === 'equals' ? 'EQUAL' : filter.type
        }

        return filterObject
      })

      const payload = {
        page,
        size: pageSize,
        sort: sortPart.length > 0 ? sortPart[0].sort : 'createdAt',
        order: sortPart.length > 0 ? sortPart[0].order : 'desc',
        filters: filterPart
      }

      try {
        let response
        if (apiMethod === 'POST') {
          response = await axios.post(apiEndpoint, payload, { headers })
        } else {
          const queryString = `?page=${page}&size=${pageSize}`;
          response = await axios.get(apiEndpoint + queryString, { headers })
        }

        const rows = response.data.content.map((row: { createdAt: any; modifiedAt: any }) => ({
          ...row,
          createdAt: getLocalDate(row.createdAt, true),
          modifiedAt: getLocalDate(row.modifiedAt, true)
        }))

        return { rows, total: response.data.totalElements }
      } catch (error) {
        return { rows: [], total: 0 }
      } finally {
        if (gridRef.current) {
          gridRef.current.api.hideOverlay()
        }
      }
    },
    [apiEndpoint, apiMethod, headers]
  )

  const onGridReady = useCallback(
    (params: GridReadyEvent) => {
      setGridApi(params)
      const datasource = {
        getRows: async (params: IGetRowsParams) => {
          const { startRow, sortModel, filterModel } = params
          const page = Math.floor(startRow / pageSize)
          const { rows, total } = await fetchData(page, sortModel, filterModel)
          params?.successCallback(rows, total)
        }
      }
      params?.api?.setDatasource(datasource)
      const isSiteAdmin = userData?.groups.some((group: {name: string}) => group?.name === USER_ROLE.SAD)
      if (!isSiteAdmin) {
        const currentFilterModel = params?.api?.getFilterModel()
        if (currentFilterModel) {
          currentFilterModel['networkName'] = {
            filter: agency,
            type: 'contains'
          }
        }

        params?.api?.setFilterModel(currentFilterModel)
      }
    },
    [fetchData, userData]
  )

  const onRowClicked = useCallback(
    (event: any) => {
      onRowClickedHandler && onRowClickedHandler(event.data)
    },
    [onRowClickedHandler]
  )

  const clearFiltersAndSorting = () => {
    if (gridRef.current) {
      gridRef.current.api.setFilterModel(null)
      gridRef.current.columnApi.applyColumnState({
        state: headerDetails.map(col => ({
          colId: col.field,
          sort: null
        }))
      })
    }
  }

  useEffect(() => {
    if (gridRef.current && isTriggerReload) {
      onGridReady(gridApi);
    }
  }, [isTriggerReload]);

  return (
    <div className="ag-grid-outer-container">
      <div className="ag-grid-area">
        <div className="ag-theme-material ag-theme-alpine ag-grid-container">
          <AgGridReact
            ref={gridRef}
            columnDefs={headerDetails}
            defaultColDef={defaultColDef}
            rowSelection="single"
            onGridReady={onGridReady}
            suppressRowClickSelection={true}
            onRowClicked={onRowClicked}
            domLayout="autoHeight"
            pagination
            paginationPageSize={pageSize}
            cacheBlockSize={pageSize}
            rowModelType="infinite"
            loadingOverlayComponent={Loading}
          />
        </div>
      </div>
      {isClearFilters && (
        <div className="clear-filters-container">
          <WppButton className="customBtnSecondary" variant="secondary" onClick={clearFiltersAndSorting} size="s">
            Clear Filters and Sorting
          </WppButton>
        </div>
      )}
    </div>
  )
}

export default DataGrid
