import React, { useEffect, useState } from 'react'

import { Grid, Container } from '@mui/material'
import { WppLabel, WppListItem, WppSelect, WppTypography } from '@wppopen/components-library-react'
import { useOs } from '@wppopen/react'
import { connect } from 'react-redux'

import { getTemplatesDispatcher, selectedTemplateDispatcher } from './action'
import { Step2Props } from './interface'
import serviceURL from '../../../../../helper/serviceURL'
import useAxiosInterceptors from '../../../../../hooks/useAxiosInterceptors'
import styles from '../step1/Step1.module.scss'

const mapStateToProps = (state: any) => {
  return {
    selectedTemplate: state.caSelectedTemplateReducer.data,
    templateList: state.caGetTemplateReducer.data,
    selectedType: state.caSelectedTypeReducer.data,
    userContext: state.getOrgByUserRed.context
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  selectedTemplateDispatcher: (template: object) => dispatch(selectedTemplateDispatcher(template)),
  getTemplatesDispatcher: (templateList: any, type: { name: string }) =>
    dispatch(getTemplatesDispatcher(templateList, type))
})
const Step2 = ({
  selectedTemplateDispatcher,
  getTemplatesDispatcher,
  selectedTemplate,
  validationErrors,
  templateList,
  selectedType,
  setValidationErrors,
  userContext
}: Step2Props) => {
  const {
    osApi: { getAccessToken }
  } = useOs()
  const headers = {
    accept: '*/*',
    Authorization: 'Bearer ' + getAccessToken()
  }
  const { axiosInstance } = useAxiosInterceptors()
  const [hasSameNetwork, setHasSameNetwork] = useState(true)
  const [organizationTemplate, setOrganizationTemplate] = useState<
    Array<{ id: number; assessmentTemplates: { id: string }[] }>
  >([])

  useEffect(() => {
    validateTemplateWithNetwork()
    getTemplatesDispatcher && getTemplatesDispatcher(headers, selectedType)
  }, [])

  const updateValidationList = (fieldName: string) => {
    return validationErrors?.filter((item: any) => item.name !== fieldName)
  }

  const validateTemplateWithNetwork = () => {
    const apiUrl = serviceURL.pgpBaseAPI + '/api/organizations/templates'
    axiosInstance
      .get(apiUrl)
      .then((res: any) => {
        if (res) {
          setOrganizationTemplate(res?.data)
        }
      })
      .catch((error: any) => {
        console.log(error)
      })
  }

  useEffect(() => {
    if (selectedTemplate?.id) {
      const hasMatchingTemplateId = organizationTemplate.some(
        item =>
          userContext?.id === item?.id &&
          item?.assessmentTemplates.some(template => template?.id === selectedTemplate?.id)
      )
      setHasSameNetwork(hasMatchingTemplateId)
    }
  }, [selectedTemplate])

  const handleTemplateChange = event => {
    selectedTemplateDispatcher && selectedTemplateDispatcher(event.target.value)
    setValidationErrors && setValidationErrors(updateValidationList('selectedTemplate'))
  }

  return (
    <Container maxWidth="xl">
      <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }} rowSpacing={5}>
        <Grid item xs={12} sm={12} md={12}>
          <WppTypography>
            Please select the appropriate template for this assessment. Each template has a different purpose. If you
            are not sure of which one to use, please contact sdn.support@wpp.com
          </WppTypography>
        </Grid>
        <Grid item xs={12} sm={4} md={4}>
          <WppLabel
            className={styles.label}
            config={{ text: 'Assessment Template' }}
            htmlFor="assessment-template"
            typography="s-strong"
          />
          <WppSelect
            id="assessment-template"
            onWppChange={e => handleTemplateChange(e)}
            placeholder="Select Template"
            value={selectedTemplate}
            withSearch={true}
            size="s"
            message={
              (validationErrors && validationErrors.find((item: any) => item.name === 'selectedTemplate')?.error) || ''
            }
            messageType={
              validationErrors && validationErrors.find((item: any) => item.name === 'selectedTemplate')?.error
                ? 'error'
                : undefined
            }
          >
            {templateList &&
              templateList.map(
                (
                  item: {
                    name: string
                  },
                  idx: number
                ) => {
                  return (
                    <WppListItem key={idx} value={item} defaultValue={selectedTemplate && selectedTemplate.name}>
                      <p slot="label">{item.name}</p>
                    </WppListItem>
                  )
                }
              )}
          </WppSelect>
        </Grid>
        {!hasSameNetwork && (
          <WppTypography type="m-strong" className={styles.message}>
            The selected template does not match your network. Are you sure you want to continue?
          </WppTypography>
        )}
      </Grid>
    </Container>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Step2)
