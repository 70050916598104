import React, { useCallback, useEffect, useState } from 'react'

import { Container, Grid } from '@mui/material'
import { WppButton, WppIconAdd, WppToast } from '@wppopen/components-library-react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

import CreateVendors from './createVendors/CreateVendors'
import { rowDataProps } from './interface'
import VendorDetails from './rowDetails/VendorDetails'
import styles from './VendorsList.module.scss'
import DataGrid from '../../../components/dataGrid/DataGrid'
import { SELECT_TYPES, VENDORS_GRID_HEADER_DETAILS } from '../../../helper/constants'
import isObjectEmpty from '../../../helper/Helper'
import serviceURL from '../../../helper/serviceURL'
import { setCreateVendorData, getInitialData, setTriggerReload } from './rowDetails/action'

const VendorsList = React.memo(() => {
  const createdVendorDetails = useSelector((state: any) => state?.createVendorDetails?.data)
  const isTriggerReload = useSelector((state: any) => state?.triggerReload?.data)
  const vendorDetails = useSelector((state: any) => state.vendorDetails.vendor)
  const dispatch = useDispatch()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const initialRowData = {
    id: '',
    name: '',
    description: '',
    legalEntityInventory: '',
    vendorInventory: '',
    daInventory: '',
    paInventory: ''
  }
  const [clickedRowData, setClickedRowData] = useState<any>(initialRowData)
  const [openClickedRowModal, setOpenClickedRowModal] = useState(false)
  const [showToast, setShowToast] = useState(false)
  const baseApiUrl = `${serviceURL.pgpBaseAPI}/api/inventory/VENDOR`

  const navigate = useNavigate()
  let { inventoryId } = useParams()

  useEffect(() => {
    if (inventoryId && createdVendorDetails?.id && createdVendorDetails?.name) {
      dispatch(setTriggerReload(true))
      setClickedRowData(createdVendorDetails)
      setOpenClickedRowModal(true)
    }
  }, [inventoryId, createdVendorDetails])

  useEffect(() => {
    if (inventoryId && vendorDetails?.id && vendorDetails?.name) {
      setClickedRowData(vendorDetails)
      setOpenClickedRowModal(true)
    }
  }, [inventoryId, vendorDetails])

  const getClickedRow = useCallback((data: rowDataProps): void => {
    if (!isObjectEmpty(data)) {
      setClickedRowData(data)
      setOpenClickedRowModal(true)
    }
  }, [])
  const handleCreateVendor = useCallback((): void => {
    setIsModalOpen(true)
    setShowToast(false)
  }, [])

  const getShowToast = (val: any) => {
    setShowToast(val)
  }

  const handleModalClose = () => {
    dispatch(setCreateVendorData({}))
    dispatch(setTriggerReload(false))
    dispatch(getInitialData({vendorData: {}, assessment: {}}))
    setOpenClickedRowModal(false)
    navigate('/vrm/vendors-list')
  }

  return (
    <div className={styles.vContainer}>
      <Container maxWidth="xl">
        <div className={styles.vLoadingArea}>
          {showToast && <WppToast className={styles.vToast} message="Vendor created successfully" type="success" />}
        </div>
        <Grid
          container
          spacing={{ xs: 2, md: 3, sm: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
          className={styles.vendorContainer}
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            display="flex"
            justifyContent="flex-start"
            alignSelf="flex-start"
            className={styles.createVendorButton}
          >
            <WppButton className={styles.customBtnSecondary} variant="secondary" onClick={handleCreateVendor} size="s">
              <WppIconAdd slot="icon-start" className={styles.customIcon} />
              Create Vendor
            </WppButton>
          </Grid>
          <Grid item xs={12} sm={12} md={12} style={{ paddingTop: '3rem', marginBottom: '5rem', height: '100vh' }}>
            <DataGrid
              apiEndpoint={baseApiUrl + '/search'}
              headerDetails={VENDORS_GRID_HEADER_DETAILS}
              apiMethod="POST"
              pageSize={25}
              onRowClickedHandler={getClickedRow}
              isClearFilters={true}
              isTriggerReload={isTriggerReload}
            />
          </Grid>
        </Grid>
        {isModalOpen && (
          <CreateVendors open={isModalOpen} onClose={() => setIsModalOpen(false)} showToast={getShowToast} />
        )}
        {openClickedRowModal && (
          <VendorDetails
            open={openClickedRowModal}
            clickedRowData={clickedRowData}
            typeOfParentInventory={SELECT_TYPES[2]}
            onClose={handleModalClose}
          />
        )}
      </Container>
    </div>
  )
})

export default VendorsList
