import axios from 'axios';

import {GET_INVENTORY_BY_ID, LAUNCH_ASSESS, CLEAR_SELECTED_ROW_DATA} from './types';
import {INVENTORY} from '../../../../helper/constants';
import {serviceURL} from '../../../../helper/serviceURL';
export const getInventoryById = (invDetails: any) => ({
    type: GET_INVENTORY_BY_ID,
    payload: invDetails
});

export const clearClickedInvId = (invAssetDetails: any) => ({
    type: CLEAR_SELECTED_ROW_DATA,
    payload: invAssetDetails
});

export const getInventoryByIdDispatcher = (type: string, id: string, header: object) => (dispatch: any) => {
    const apiUrl = serviceURL.pgpBaseAPI + '/api/inventory/' + type + '/' + id;
    return new Promise((resolve, reject) => {
        axios.get(apiUrl, {
            headers: header
        }).then((res: any) => {
            let modObj: any;
            if (res.data) {
                modObj = res.data
            } else {
                modObj = res.data;
            }
            dispatch(getInventoryById(modObj));
            resolve(modObj);
        }).catch((error) => reject(error));
    });
};

export const launchAssess = (obj: object) => ({
    type: LAUNCH_ASSESS,
    payload: obj,
});

export const launchAssessDispatcher = (obj: object) => (dispatch: any) => {
    dispatch(launchAssess(obj));
};

export const clearInventoryByIdDispatcher = (invRes: object) => (dispatch: any) => {
    return new Promise((res) => {
        dispatch(clearClickedInvId(invRes));
        res(invRes);
    });
};