import { Grid } from '@mui/material'
import { WppTag } from '@wppopen/components-library-react'

import isObjectEmpty from 'helper/Helper'

import { searchData } from '../../helper/interface'

const SearchResultComp = (props: any) => {
  const { searchResultList } = props
  if (searchResultList && Array.isArray(searchResultList) && searchResultList.length > 0) {
    return (
      <Grid item xs={12} sm={12} md={12} style={{ paddingTop: '2rem' }}>
        <Grid container justifyContent="flex-start" spacing={2}>
          <Grid item>
            <WppTag label="Filtered By:" categoricalColorIndex={8} />
          </Grid>
          <Grid item>
            <Grid container spacing={1}>
              {searchResultList.map((item: searchData) => {
                return (
                  <Grid item key={item.id} className="itemValue" xs="auto">
                    <WppTag label={item.name + ':  ' + item.value + ' '} variant="positive" />
                    &nbsp;
                  </Grid>
                )
              })}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  } else if (
    typeof searchResultList === 'object' &&
    Array.isArray(searchResultList) === false &&
    isObjectEmpty(searchResultList) === false
  ) {
    return (
      <Grid item xs={12} sm={12} md={12} style={{ paddingTop: '2rem' }}>
        <Grid container direction="column" justifyContent="flex-start" spacing={0} />
        <Grid item xs={12} sm={12} md={12} marginBottom="1rem">
          <WppTag label="Filtered By:" categoricalColorIndex={8} />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <Grid container spacing={2} justifyContent="flex-start">
            <Grid item>
              <WppTag label={searchResultList.name + ':  ' + searchResultList.value + ' '} variant="positive" />
              {/* <WppTag label={searchResultList.value} variant="positive"/> */}
              {/*<span className={searchStyles.searchKey}>{searchResultList.name}: </span>*/}
              {/*<span className={searchStyles.searchValue}>{searchResultList.value}</span>*/}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }
  return null
}

export default SearchResultComp
