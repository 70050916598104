import React from 'react'

import styles from '../containers/piaModule/assessmentList/AssessmentList.module.scss'

const TagTotalRiskIndicator = (params: { data: { riskRange: string }; value: number }) => {
  const getLevel = (rangeResult: { min: number; max: number; level: string }[], value: number) => {
    const result = rangeResult.find(item => {
      return value >= item.min && value <= item.max
    })
    return result ? result.level : ''
  }

  const calculateRiskLevel = (range: string, value: number) => {
    if (!range) return
    const rangeResult = JSON.parse(range)
    return getLevel(rangeResult, value)
  }

  const categoryColor = (riskRange: string, value: number) => {
    const riskLevel = calculateRiskLevel(riskRange, value)
    switch (riskLevel) {
      case 'VERY_LOW':
        return 'veryLowRisk'
      case 'LOW':
        return 'lowRisk'
      case 'MEDIUM':
        return 'mediumRisk'
      case 'HIGH':
        return 'highRisk'
      case 'VERY_HIGH':
        return 'veryHighRisk'
      default:
        return ''
    }
  }

  return (
    <>
      {params?.value !== null && params?.value !== undefined ? (
        <div className={styles[categoryColor(params?.data?.riskRange, params?.value)]}>{params?.value}</div>
      ) : null}
    </>
  )
}

export default TagTotalRiskIndicator
