import React, { useState } from 'react'

import { WppButton } from '@wppopen/components-library-react'
import jsPDF from 'jspdf'
import 'jspdf-autotable'

import { getLocalDate } from 'helper/Helper'


import styles from './pdf.module.scss'

const VendorPdf = ({ data, isLoading }: any) => {
  const [loading, setLoading] = useState(false)

  const generatePDF = () => {
    try {
      setLoading(true)
      const doc = new jsPDF('p', 'pt', 'a4')
      const margin = 20
      const pageWidth = doc.internal.pageSize.width
      let yPos = margin

      const addTextWithBackground = (
        text: string | string[],
        yPos: number,
        fontSize: number,
        backgroundColor: { r: any; g: any; b: any },
        verticalSpacing: number
      ) => {
        doc.setFontSize(fontSize)
        const textHeight = fontSize / 2

        doc.setFillColor(backgroundColor.r, backgroundColor.g, backgroundColor.b)
        doc.rect(margin, yPos, pageWidth - margin * 2, textHeight + verticalSpacing * 2, 'F')

        doc.setTextColor(0, 0, 0)
        doc.setFont('helvetica', 'bold')
        doc.text(text, margin + 10, yPos + textHeight + verticalSpacing)

        return yPos + textHeight + verticalSpacing * 2 + 10 // Adding padding for the next line
      }

      const addHeaderSection = () => {
        yPos = addTextWithBackground('Safer Data Navigator (SDN)', yPos, 12, { r: 224, g: 250, b: 234 }, 10)

        doc.setFontSize(8)
        doc.setTextColor(0, 0, 0)
        doc.setFont('helvetica')
        doc.text('Vendor Details', margin, yPos + 20)
        yPos += 30

        const vendorheaderData = [
          ['Name:', data?.name || ''],
          ['Industry:', data?.industry?.name || ''],
          ['Location:', data?.country?.name],
          ['Vendor Status:', data?.status],
          ['Key Contact:', data?.modifiedBy || ''],
          ['Created By:', data?.createdBy],
          ['Created At:', data?.createdAt ? getLocalDate(data?.createdAt) : ''],
          ['Last Activity Date:', data?.modifiedAt ? getLocalDate(data?.modifiedAt) : '']
        ]

        doc.autoTable({
          body: vendorheaderData,
          startY: yPos,
          theme: 'grid',
          bodyStyles: { fillColor: [224, 250, 234] },
          styles: { fontSize: 8, textColor: [0, 0, 0], overflow: 'linebreak' },
          columnStyles: {
            0: { fontStyle: 'bold', cellWidth: pageWidth * 0.3 },
            1: { textColor: [0, 0, 0], cellWidth: pageWidth * 0.6 }
          },
          tableWidth: 'auto',
          margin: { left: margin, right: margin }
        })

        yPos = doc.autoTable.previous.finalY // Update yPos after adding header section
      }

      addHeaderSection()

      // Save the PDF
      const fileName = data?.name ? `${data.name.toLowerCase().trim()}_vendor_report.pdf` : 'vendor_report.pdf'
      doc.save(fileName)
    } catch (error) {
      console.error('Error generating PDF:', error)
    } finally {
      setLoading(false)
    }
  }
  return (
    <WppButton className={styles.pdfBtn} variant="secondary" disabled={isLoading || loading} onClick={generatePDF}>
      Export PDF
    </WppButton>
  )
}

export default VendorPdf
