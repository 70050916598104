import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { useOs } from '@wppopen/react'
import { RowClickedEvent } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import { connect } from 'react-redux'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'

import CustomLoading from './CustomLoading'
import CustomNoRows from './CustomNoRows'
import './DataGridCompModule.scss'
import { DataGridCompProps } from './interface'
import {
  getInventoryByIdDispatcher,
  launchAssessDispatcher
} from '../../containers/dmModule/inventoryList/rowDetails/action'
import { selectedTypeDispatcher } from '../../containers/piaModule/assessmentList/createAssessment/step1/action'
import { delayCall } from '../../helper/Helper'

const mapDispatchToProps = (dispatch: any) => ({
  getInventoryByIdDispatcher: (type: string, id: string, head: object, orgId: number) =>
    dispatch(getInventoryByIdDispatcher(type, id, head, orgId)),
  selectedTypeDispatcher: (type: object) => dispatch(selectedTypeDispatcher(type)),
  launchAssessDispatcher: (obj: object) => dispatch(launchAssessDispatcher(obj))
})
const mapStateToProps = (state: any) => {
  return {
    base64Email: state.storeBase64EmailReducer.data,
    rowDetails: state.inventoryRowDetailsRed.data,
    selectedSideOrg: state.selectedSideNavOrgRed.data
  }
}

const DataGridComp = (props: DataGridCompProps) => {
  const {
    columnHeaderDefinition,
    prepareRow,
    clickedRow,
    baseApiUrl,
    headers = { 'Content-Type': 'application/json' },
    initialGridResult,
    totalElements,
    isFetchBySelectType = false,
    selectedType = null,
    fetchListUrl,
    queryVal,
    createParentModule,
    noCustomRowBtn,
    type
  } = props
  const {
    osApi: { getAccessToken }
  } = useOs()
  type TableDataItem = (typeof initialGridResult)[0]
  const gridRef = useRef<AgGridReact<TableDataItem>>(null)
  const [gridApi, setGridApi] = useState(null)
  const [noOfRecordsPerPage, setNoOfRecordsPerPage] = useState(25)
  let nextApiRecords: Object[] = []
  let nextTotalElements: number | null = null
  const orgId = fetchListUrl && fetchListUrl.split('orgId=')[1]
  const createActionNamefromUrl = (baseApiUrl && fetchListUrl.split('/api/')[1]) || fetchListUrl.split('/dps/')[1]
  let createActionModule = ''
  if (createActionNamefromUrl.includes('VENDOR')) {
    createActionModule = 'Create Vendor'
  } else if (createActionNamefromUrl.includes('assessments')) {
    createActionModule = 'Create Assessment'
  } else if (createActionNamefromUrl.includes('inventory')) {
    createActionModule = 'Create Inventory'
  } else {
    createActionModule = ''
  }
  const defaultColDef = useMemo(() => {
    return { resizable: true }
  }, [])
  const createModule = useCallback((): void => {
    createParentModule && createParentModule()
  }, [])

  const noRowsComponentParams = useMemo(() => {
    return { noRowsMessageFunc: () => 'No Data Found!' }
  }, [])
  const loadingComponent = useMemo(() => {
    return CustomLoading
  }, [])
  const loadingComponentParams = useMemo(() => {
    return { loadingMessage: 'Loading...' }
  }, [])

  const onRowClicked = useCallback((event: RowClickedEvent): void => {
    if (event.data) {
      clickedRow(event.data)
    }
  }, [])

  const patchTypeUrl = (isType: boolean, type: string | null): string | null => {
    return isType && type ? `type=${type}&` : null
  }
  const patchSearchUrl = (isSearch: boolean): string | null => {
    return isSearch ? (type === 'Vendor' ? '/search?' : `/search?orgId=${orgId}&`) : null
  }
  const rowStyle = { cursor: 'pointer' }

  const fetchGridList = async (startRow: number = 0, endRow: number = noOfRecordsPerPage, queryString: string = '') => {
    const apiUrl = queryString

    function handleErrors(response: any) {
      if (!response.ok) {
        nextApiRecords = []
        throw Error(response.statusText)
      }
      return response
    }

    const requestHeaders: HeadersInit = new Headers()
    for (const [key, value] of Object.entries(headers)) {
      requestHeaders.set(key, value)
    }

    try {
      const response = await fetch(`${baseApiUrl}/search`, {
        method: 'POST',
        headers: {
          ...requestHeaders,
          Authorization: 'Bearer ' + getAccessToken(),
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          page: startRow,
          size: 25,
          sort: 'createdAt',
          order: 'desc',
          filters: []
        })
      })

      handleErrors(response)
      const result = await response.json()

      if (result) {
        let sanitizedData = prepareRow(result.content)
        if (sanitizedData && sanitizedData.length > 0) {
          nextApiRecords = [...sanitizedData]
          nextTotalElements = result.totalElements
        } else {
          nextApiRecords = []
        }
      }
    } catch (error) {
      console.error(error)
      nextApiRecords = []
    }
  }

  const postSortHandler = (gridRef: any, params: any) => {
    delayCall(() => {
      if (nextApiRecords.length > 0) {
        params.successCallback(nextApiRecords, nextTotalElements)
        gridRef.current && gridRef.current!.api.hideOverlay()
      } else {
        params.successCallback([], 0)
        gridRef.current && gridRef.current!.api.hideOverlay()
        gridRef.current && gridRef.current!.api.showNoRowsOverlay()
      }
    }, 1000)
  }
  const dataSource = {
    rowCount: totalElements || undefined,
    getRows: async (params: any) => {
      const { startRow, sortModel } = params
      let url = `${baseApiUrl}`
      let sortUrl = null
      let searchUrl = queryVal && queryVal.length > 0 ? queryVal : null

      if (sortModel.length) {
        const { colId, sort } = sortModel[0]
        sortUrl = `&sort=${colId}&order=${sort}`
      }
      gridRef.current && gridRef.current!.api.showLoadingOverlay()
      if (totalElements) {
        for (let i = startRow / noOfRecordsPerPage; i < Math.ceil(totalElements / noOfRecordsPerPage); i++) {
          if (startRow === 0) {
            if (searchUrl && searchUrl !== 'reset') {
              url += `${patchSearchUrl(true)}`
              if (patchTypeUrl(isFetchBySelectType, selectedType)) {
                url += `${patchTypeUrl(isFetchBySelectType, selectedType)}${searchUrl}`
              } else {
                url += `${searchUrl}`
              }
              if (sortModel.length) {
                url += `${sortUrl}`
              }
              url += `&page=${startRow}&size=${noOfRecordsPerPage}`
              gridRef.current && gridRef.current!.api.showLoadingOverlay()
              await fetchGridList(i, noOfRecordsPerPage, url)
              postSortHandler(gridRef, params)
              break
            } else {
              if (searchUrl === 'reset') {
                url = fetchListUrl
                if (sortModel.length) {
                  url += sortUrl
                }
                url += `&page=${startRow}&size=${noOfRecordsPerPage}`
                gridRef.current && gridRef.current!.api.showLoadingOverlay()
                await fetchGridList(i, noOfRecordsPerPage, url)
                postSortHandler(gridRef, params)
                break
              } else {
                if (sortModel.length) {
                  url = fetchListUrl + sortUrl
                  url += `&page=${startRow}&size=${noOfRecordsPerPage}`
                  gridRef.current && gridRef.current!.api.showLoadingOverlay()
                  await fetchGridList(i, noOfRecordsPerPage, url)
                  postSortHandler(gridRef, params)
                  break
                } else {
                  delayCall(() => {
                    params.successCallback(initialGridResult, totalElements)
                    gridRef.current && gridRef.current!.api.hideOverlay()
                  }, 300)
                  break
                }
              }
            }
          } else {
            if (searchUrl && searchUrl !== 'reset') {
              url += `${patchSearchUrl(true)}`
              if (patchTypeUrl(isFetchBySelectType, selectedType)) {
                url += `${patchTypeUrl(isFetchBySelectType, selectedType)}${searchUrl}`
              } else {
                url += `${searchUrl}`
              }
            } else {
              url = fetchListUrl
            }
            if (sortModel.length) {
              url += `${sortUrl}`
            }
            url += `&page=${i}&size=${noOfRecordsPerPage}`
            gridRef.current && gridRef.current!.api.showLoadingOverlay()
            await fetchGridList(i, noOfRecordsPerPage, url)
            postSortHandler(gridRef, params)
            break
          }
        }
      } else {
        delayCall(() => {
          params.successCallback([], 0)
          gridRef.current && gridRef.current!.api.hideOverlay()
          gridRef.current && gridRef.current!.api.showNoRowsOverlay()
        }, 1000)
      }
    }
  }
  const getRowId = useCallback(function (params: any) {
    return params.data.id
  }, [])
  const onGridReady = async (params: any) => {
    setGridApi(params)
    params.api.setDatasource(dataSource)
  }

  useEffect(() => {
    if (queryVal && queryVal.length > 0) {
      if (gridApi) {
        onGridReady(gridApi)
      }
    }
  }, [queryVal])

  return (
    <div className="ag-grid-outer-container">
      <div className="ag-grid-area">
        <div className="ag-theme-material ag-theme-alpine ag-grid-container">
          <AgGridReact
            ref={gridRef}
            rowStyle={rowStyle}
            columnDefs={columnHeaderDefinition}
            defaultColDef={defaultColDef}
            rowModelType="infinite"
            pagination={true}
            paginationPageSize={noOfRecordsPerPage}
            cacheBlockSize={noOfRecordsPerPage}
            getRowId={getRowId}
            animateRows={true}
            domLayout="normal"
            onGridReady={onGridReady}
            rowSelection="single"
            loadingOverlayComponent={loadingComponent}
            loadingOverlayComponentParams={loadingComponentParams}
            noRowsOverlayComponent={() => (
              <CustomNoRows
                noCustomRowBtn={noCustomRowBtn}
                createModule={createModule}
                createActionModule={createActionModule}
              />
            )}
            noRowsOverlayComponentParams={noRowsComponentParams}
            onRowClicked={e => onRowClicked(e)}
          />
        </div>
      </div>
    </div>
  )
}
export default connect(mapStateToProps, mapDispatchToProps)(DataGridComp)
