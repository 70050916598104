import React, { useCallback, useMemo, useState } from 'react'

import { WppButton } from '@wppopen/components-library-react'
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import axios from 'axios'
import { useOs } from '@wppopen/react'

import { getLocalDate } from 'helper/Helper'

import styles from './pdf.module.scss'

const VendorPdf = ({ isLoading, apiEndpoint }: any) => {
  const [loading, setLoading] = useState(false)
  const {
    osContext,
    osApi: { getAccessToken }
  } = useOs()
  const { email } = osContext?.userDetails
  const headers = useMemo(
    () => ({
      accept: '*/*',
      Authorization: `Bearer ${getAccessToken()}`,
      'Content-Type': 'application/json'
    }),
    [getAccessToken]
  )

  const fetchData = useCallback(async () => {
    try {
      let response: any = await axios.get(apiEndpoint, { headers })
      const { assessments, ...props } = response?.data
      const rows = assessments?.map((row: any) => ({
        ...row,
        reviwerAt: getLocalDate(row?.reviwerAt, true)
      }))

      return { rows, data: props }
    } catch (error) {
      return { rows: [], total: 0 }
    } finally {
    }
  }, [apiEndpoint])

  const generatePDF = async () => {
    try {
      setLoading(true)
      const { data, rows } = await fetchData()
      if (Object.keys(data).length > 0) {
        const doc = new jsPDF('p', 'pt', 'a4')
        const margin = 20
        const pageWidth = doc.internal.pageSize.width
        let yPos = margin

        const addTextWithBackground = (
          text: string | string[],
          yPos: number,
          fontSize: number,
          backgroundColor: { r: any; g: any; b: any },
          verticalSpacing: number
        ) => {
          doc.setFontSize(fontSize)
          const textHeight = fontSize / 2

          doc.setFillColor(backgroundColor.r, backgroundColor.g, backgroundColor.b)
          doc.rect(margin, yPos, pageWidth - margin * 2, textHeight + verticalSpacing * 2, 'F')

          doc.setTextColor(0, 0, 0)
          doc.setFont('helvetica', 'bold')
          doc.text(text, margin + 10, yPos + textHeight + verticalSpacing)

          return yPos + textHeight + verticalSpacing * 2 + 10 // Adding padding for the next line
        }

        const addHeaderSection = () => {
          yPos = addTextWithBackground('Safer Data Navigator (SDN)', yPos, 12, { r: 224, g: 250, b: 234 }, 10)

          doc.setFontSize(8)
          doc.setTextColor(0, 0, 0)
          doc.setFont('helvetica')
          doc.text('Vendor Details', margin, yPos + 20)
          yPos += 30

          const vendorheaderData = [
            ['Name:', data.name || ''],
            ['Industry:', data.industry || ''],
            ['Location:', data.location || ''],
            ['Vendor Status:', data?.status || ''],
            ['Created By:', data.createdBy || ''],
            ['Created At:', data.createdAt ? getLocalDate(data?.createdAt) : ''],
          ]

          doc.autoTable({
            body: vendorheaderData,
            startY: yPos,
            theme: 'grid',
            bodyStyles: { fillColor: [224, 250, 234] },
            styles: { fontSize: 8, textColor: [0, 0, 0], overflow: 'linebreak' },
            columnStyles: {
              0: { fontStyle: 'bold', cellWidth: pageWidth * 0.3 },
              1: { textColor: [0, 0, 0], cellWidth: pageWidth * 0.6 }
            },
            tableWidth: 'auto',
            margin: { left: margin, right: margin }
          })

          yPos = doc.autoTable.previous.finalY // Update yPos after adding header section
          if (rows.length) {
          doc.setFontSize(8)
          doc.setTextColor(0, 0, 0)
          doc.setFont('helvetica')
          doc.text('Most Recent Approved Assessment(s)', margin, yPos + 20)
          yPos += 30
          
            rows.map((row: any) => {
              const headerData = [
                ['Agency:', row?.agency || ''],
                ['Created At:', row?.createdAt ? getLocalDate(row?.createdAt) : ''],
                ['Template:', row?.template || ''],
                [
                  'Respondents:',
                  row?.respondents.length
                    ? row?.respondents.map((respondent: any) => respondent).join(', ')
                    : ''
                ],
                ['Status:', row.status || ''],
                ['Reviewed At:', row?.reviwerAt ? getLocalDate(row?.reviwerAt) : ''],
                ['Reviewed By:', row.reviewedBy || '']
              ]

              doc.autoTable({
                body: headerData,
                startY: yPos,
                theme: 'grid',
                styles: { fontSize: 8, textColor: [0, 0, 0], overflow: 'linebreak' },
                columnStyles: {
                  0: { fontStyle: 'bold', cellWidth: pageWidth * 0.3 },
                  1: { textColor: [0, 0, 0], cellWidth: pageWidth * 0.6 }
                },
                tableWidth: 'auto',
                margin: { left: margin, right: margin }
              })

              yPos = doc.autoTable.previous.finalY + margin // Update yPos after adding header section
            })
          }
        }

        const addFooter = () => {
          const totalPages = doc.getNumberOfPages();
          const pageHeight = doc.internal.pageSize.height;
          for (let i = 1; i <= totalPages; i++) {
            doc.setPage(i);
            doc.setFontSize(8);
            doc.setTextColor(150);
            doc.text(
              `Report generated by ${email} on ${getLocalDate(Date.now())}`,
              pageWidth / 2,
              pageHeight - 10,
              { align: 'center' }
            );

          }
        };

        addHeaderSection();
        addFooter();

        // Save the PDF
        const fileName = data?.name ? `${data.name.toLowerCase().trim()}_vendor_report.pdf` : 'vendor_report.pdf'
        doc.save(fileName)
      }
    } catch (error) {
      console.error('Error generating PDF:', error)
    } finally {
      setLoading(false)
    }
  }
  return (
    <WppButton className={styles.pdfBtn} variant="secondary" disabled={isLoading || loading} onClick={generatePDF}>
      Export PDF
    </WppButton>
  )
}

export default VendorPdf
