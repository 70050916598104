import { Chart } from 'chart.js';
import { ChartConfiguration } from 'chart.js/auto';
import * as echarts from 'echarts';

import { commonOptions } from 'helper/Helper';

import customMapData from './usa.json';
const removeExistingChartInstance = (chartRef: any) => {
    const existingChart = Chart.getChart(chartRef);
    if (existingChart) {
        existingChart.destroy();
    }
};

const removeExistingEChartInstance = (chartRef: any) => {
    const existingChart = echarts.getInstanceByDom(chartRef);
    if (existingChart) {
        existingChart.dispose();
    }
};

const createChartObj = (count: any, data: any, chartRef: any, options: any, type: any) => {
    if (!chartRef) {
        return;
    }
    removeExistingChartInstance(chartRef);
    if (count.every((ele: any) => ele === 0)) {
        chartRef.canvas.height = 255;
        chartRef.beginPath();
        chartRef.lineWidth = 32;
        chartRef.strokeStyle = 'lightgrey';
        chartRef.arc(150, 115, 55, 0, 2 * Math.PI);
        chartRef.fillStyle = 'black';
        chartRef.textAlign = 'center';
        chartRef.font = '14px Inter, sans-serif';
        chartRef.fillText('No data', 150, 120);
        chartRef.stroke();
    } else {
        new Chart(chartRef, {
            type: type,
            data: data,
            options: options,
        });
    }
};
export const buildChartPortalType = (
    canvasRefPortalType: any,
    portalTypeNames: string[],
    portalTypeCounts: string[],
    bgColors: string[]) => {
    const data = {
        labels: portalTypeNames,
        datasets: [
            {
                label: 'Portal Types',
                data: portalTypeCounts,
                backgroundColor: bgColors,
                hoverOffset: 10,
            },
        ],
    };
    const chartRef = canvasRefPortalType.current && canvasRefPortalType.current.getContext('2d');
    createChartObj(portalTypeCounts, data, chartRef, commonOptions, 'pie');
};

export const buildChartByStates = (canvasRefStateType: any, modifiedStateList: any) => {
    canvasRefStateType.current && removeExistingEChartInstance(canvasRefStateType.current);
    const myChart = echarts.init(canvasRefStateType.current);
    // Register the custom map data
    echarts.registerMap('USA', customMapData);
    const option = {
        tooltip: {
            trigger: 'item',
            formatter: '{b}: {c}',
            showDelay: 0,
            transitionDuration: 0.2
        },
        visualMap: {
            min: 0,
            max: 100,
            text: ['High', 'Low'],
            realtime: false,
            calculable: true,
            inRange: {
                color: [
                    '#C2F5D4',
                    '#84EBAA',
                    '#E0FAEA',
                    '#47E17F',
                    '#1FBC59',
                    '#15803D',
                    '#11652F',
                    '#0D4F25',
                    '#093519',
                    '#041A0C'],
            },
        },
        series: [
            {
                name: 'State wise data',
                type: 'map',
                map: 'USA',
                zoom: 1.2,
                // center: [139.334863, 35.6676095],
                // label: {
                //   show: true,
                // },
                emphasis: {
                    label: {
                        show: true,
                    },
                },
                data: modifiedStateList,
            },
        ],
    };
    myChart.setOption(option);
};

export const buildChartByDSARType = (
    canvasRefDsarType: any,
    dsarNames: string[],
    dsarCount: string[],
    bgColors: string[]) => {
    const data = {
        labels: dsarNames,
        datasets: [
            {
                label: 'DSAR By Type',
                data: dsarCount,
                backgroundColor: bgColors,
                hoverOffset: 10,
            },
        ],
    };
    const chartRef = canvasRefDsarType.current && canvasRefDsarType.current.getContext('2d');
    createChartObj(dsarCount, data, chartRef, commonOptions, 'doughnut');
};

export const buildChartFinishedByType = (
    canvasRefCompletedByType: any,
    completedDsarNames: string[],
    completedDsarCount: string[],
    bgColors: string[],
) => {
    const data = {
        labels: completedDsarNames,
        datasets: [
            {
                label: 'Completed',
                data: completedDsarCount,
                backgroundColor: bgColors,
                hoverOffset: 10,
            },
        ],
    };
    const chartRef = canvasRefCompletedByType.current && canvasRefCompletedByType.current.getContext('2d');
    createChartObj(completedDsarCount, data, chartRef, commonOptions, 'doughnut');
};

export const buildChartProgressDSAndDMP = (
    canvasRefProgressByType: any,
    progressDsarNames: string[],
    progressDsarCount: any,
    progressDMPCount: any,
) => {
    canvasRefProgressByType.current && removeExistingEChartInstance(canvasRefProgressByType.current);
    const myChart = echarts.init(canvasRefProgressByType.current);
    const option = {
        title: {
            text: 'DSAR Progress',
            subtext: 'By DS and DMP'
        },
        tooltip: {
            trigger: 'axis'
        },
        legend: {
            data: ['DS', 'DMP']
        },
        toolbox: {
            show: true,
            feature: {
                dataView: { show: true, readOnly: false },
                magicType: { show: true, type: ['line', 'bar'] },
                restore: { show: true },
                saveAsImage: { show: true }
            }
        },
        calculable: true,
        xAxis: [
            {
                type: 'category',
                // prettier-ignore
                data: progressDsarNames
            }
        ],
        yAxis: [
            {
                type: 'value'
            }
        ],
        series: [
            {
                name: 'DS',
                type: 'bar',
                data: progressDsarCount,
                markPoint: {
                    data: [
                        { type: 'max', name: 'Max' },
                        { type: 'min', name: 'Min' }
                    ]
                },
                markLine: {
                    data: [{ type: 'average', name: 'Avg' }]
                }
            },
            {
                name: 'DMP',
                type: 'bar',
                data: progressDMPCount,
                markPoint: {
                    data: [
                        { name: 'Max', value: 182.2, xAxis: 7, yAxis: 183 },
                        { name: 'Min', value: 2.3, xAxis: 11, yAxis: 3 }
                    ]
                },
                markLine: {
                    data: [{ type: 'average', name: 'Avg' }]
                }
            }
        ]
    };
    myChart.setOption(option);
};

export const buildChartErrorByType = (
    canvasRefErrorByType: any,
    errorDsarNames: string[],
    errorDsarCount: string[],
    bgColors: string[]) => {
    const data = {
        labels: errorDsarNames,
        datasets: [
            {
                label: 'Error',
                data: errorDsarCount,
                backgroundColor: bgColors,
                hoverOffset: 10,
            },
        ],
    };
    const chartRef = canvasRefErrorByType.current && canvasRefErrorByType.current.getContext('2d');
    createChartObj(errorDsarCount, data, chartRef, commonOptions, 'doughnut');
};

export const buildChartCancelledByType = (
    canvasRefCancelledByType: any,
    cancelledDsarNames: string[],
    cancelledDsarCount: string[],
    bgColors: string[],
) => {
    const data = {
        labels: cancelledDsarNames,
        datasets: [
            {
                data: cancelledDsarCount,
                backgroundColor: bgColors,
                hoverOffset: 10,
            },
        ],
    };
    const options: ChartConfiguration<'doughnut'>['options'] = {
        plugins: {
            legend: {
                display: false,
                position: 'right',
                align: 'start',
                maxHeight: 10,
                labels: {
                    font: {
                        size: 12,
                        family: 'Inter, Helvetica Neu, sans-serif',
                    },
                    padding: 10,
                    usePointStyle: true,
                    color: 'black',
                },
            },
        },
    };
    const chartRef = canvasRefCancelledByType.current && canvasRefCancelledByType.current.getContext('2d');
    createChartObj(cancelledDsarCount, data, chartRef, options, 'line');
};

export const buildChartProgressByDMP = (
    canvasRefProgressByDMP: any,
    progressDMPNames: string[],
    progressDMPCount: string[],
    errorDMPCount: string[],
    finishedDMPCount: string[]
) => {
    canvasRefProgressByDMP.current && removeExistingEChartInstance(canvasRefProgressByDMP.current);
    const myChart = echarts.init(canvasRefProgressByDMP.current);
    const option = {
        title: {
            text: 'DSAR Status',
            subtext: 'By DMP Progress, Error & Finished'
        },
        tooltip: {
            trigger: 'axis'
        },
        legend: {
            data: ['Progress', 'Error', 'Finished']
        },
        toolbox: {
            show: true,
            feature: {
                dataView: { show: true, readOnly: false },
                magicType: { show: true, type: ['line', 'bar'] },
                restore: { show: true },
                saveAsImage: { show: true }
            }
        },
        calculable: true,
        xAxis: [
            {
                type: 'category',
                data: progressDMPNames
            }
        ],
        yAxis: [
            {
                type: 'value'
            }
        ],
        series: [
            {
                name: 'Progress',
                type: 'bar',
                data: progressDMPCount,
            },
            {
                name: 'Error',
                type: 'bar',
                data: errorDMPCount,
            },
            {
                name: 'Finished',
                type: 'bar',
                data: finishedDMPCount,
            }
        ]
    };
    myChart.setOption(option);
};

export const buildChartErrorByDMP = (
    canvasRefErrorByDMP: any,
    errorDMPNames: string[],
    errorDMPCount: string[],
    bgColors: string[]) => {
    const data = {
        labels: errorDMPNames,
        datasets: [
            {
                label: 'DSAR Error by DMP',
                data: errorDMPCount,
                backgroundColor: bgColors,
                hoverOffset: 10,
            },
        ],
    };
    const chartRef = canvasRefErrorByDMP.current && canvasRefErrorByDMP.current.getContext('2d');
    createChartObj(errorDMPCount, data, chartRef, commonOptions, 'doughnut');
};

export const buildChartFinishedByDMP = (
    canvasRefFinishedByDMP: any,
    finishedDMPNames: string[],
    finishedDMPCount: string[],
    bgColors: string[],
) => {
    const data = {
        labels: finishedDMPNames,
        datasets: [
            {
                label: 'DSAR Finished by DMP',
                data: finishedDMPCount,
                backgroundColor: bgColors,
                hoverOffset: 10,
            },
        ],
    };
    const chartRef = canvasRefFinishedByDMP.current && canvasRefFinishedByDMP.current.getContext('2d');
    createChartObj(finishedDMPCount, data, chartRef, commonOptions, 'doughnut');
};
