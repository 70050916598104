import React, { useState } from 'react'

import { WppButton } from '@wppopen/components-library-react'
import jsPDF from 'jspdf'
import 'jspdf-autotable'

import { getLocalDate } from 'helper/Helper'


import styles from './pdf.module.scss'

const AssessmentPdf = ({ data, isLoading }: any) => {
  const [loading, setLoading] = useState(false)

  const generatePDF = () => {
    try {
      setLoading(true)
      const doc = new jsPDF('p', 'pt', 'a4')
      const margin = 20
      const pageWidth = doc.internal.pageSize.width
      let yPos = margin

      // Function to add header section using a table-like format
      const addHeaderSection = () => {
        doc.setFontSize(10)
        doc.setTextColor(0, 0, 0)
        doc.setFont('helvetica', 'bold')
        doc.text(`Assessment Report - ${data.name}`, margin, yPos + 20)
        yPos += 30

        doc.setFontSize(8)
        doc.setTextColor(0, 0, 0)
        doc.setFont('helvetica')
        doc.text('Vendor Details', margin, yPos + 20)
        yPos += 30

        const vendorheaderData = [['Name:', data.inventoryName || 'N/A']]

        doc.autoTable({
          body: vendorheaderData,
          startY: yPos,
          theme: 'grid',
          bodyStyles: { fillColor: [224, 250, 234] },
          styles: { fontSize: 8, textColor: [0, 0, 0], overflow: 'linebreak' },
          columnStyles: {
            0: { fontStyle: 'bold', cellWidth: pageWidth * 0.3 },
            1: { textColor: [0, 0, 0], cellWidth: pageWidth * 0.6 }
          },
          tableWidth: 'auto',
          margin: { left: margin, right: margin }
        })

        yPos = doc.autoTable.previous.finalY // Update yPos after adding header section

        doc.setFontSize(8)
        doc.setTextColor(0, 0, 0)
        doc.setFont('helvetica')
        doc.text('Assessment Details', margin, yPos + 20)
        yPos += 30

        const headerData = [
          ['Name:', data.name || 'N/A'],
          ['Description:', data.description || 'N/A'],
          ['Status:', data.status],
          ['Created By:', data.createdBy],
          ['Created At:', data.createdAt ? getLocalDate(data?.createdAt) : ''],
          ['Inventory Type:', data.inventoryType?.businessName || ''],
          ['Last Activity Date:', data?.modifiedAt ? getLocalDate(data?.modifiedAt) : ''],
          [
            'Respondents:',
            data?.respondents.length
              ? data?.respondents.map((respondent: { email: string }) => respondent.email).join(', ')
              : 'N/A'
          ]
        ]

        doc.autoTable({
          body: headerData,
          startY: yPos,
          theme: 'grid',
          bodyStyles: { fillColor: [224, 250, 234] },
          styles: { fontSize: 8, textColor: [0, 0, 0], overflow: 'linebreak' },
          columnStyles: {
            0: { fontStyle: 'bold', cellWidth: pageWidth * 0.3 },
            1: { textColor: [0, 0, 0], cellWidth: pageWidth * 0.6 }
          },
          tableWidth: 'auto',
          margin: { left: margin, right: margin }
        })

        yPos = doc.autoTable.previous.finalY + margin // Update yPos after adding header section
      }

      addHeaderSection()

      // Save the PDF
      const fileName = data?.name ? `${data.name.toLowerCase().trim()}_assessment_report.pdf` : 'assessment_report.pdf'
      doc.save(fileName)
    } catch (error) {
      console.error('Error generating PDF:', error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <WppButton className={styles.pdfBtn} variant="secondary" disabled={isLoading || loading} onClick={generatePDF}>
      Export PDF
    </WppButton>
  )
}

export default AssessmentPdf
