import React from 'react'

import styles from './LinkedInvCard.module.scss'

interface Props {
  key: string
  inventoryDetails: {
    id: string
    name: string
    description: string
    organization: { name: string }
    masterInventory: boolean
  }
}
export const LinkedInvCard = ({ inventoryDetails }: Props) => {
  return (
    <div className={styles.innerAccord}>
      <div>
        <span className={styles.key}>id: </span>
        <span className={styles.childValue}>{inventoryDetails?.id}</span>
      </div>
      <div>
        <span className={styles.key}>Name: </span>
        <span className={styles.childValue}>{inventoryDetails?.name}</span>
      </div>
      <div>
        <span className={styles.key}>Description: </span>
        <span className={styles.childValue}>{inventoryDetails?.description || 'NA'}</span>
      </div>
      <div>
        <span className={styles.key}>Organization: </span>
        <span className={styles.childValue}>{inventoryDetails?.organization?.name}</span>
      </div>
      <div>
        <span className={styles.key}>Master Inventory: </span>
        <span className={styles.childValue}>{inventoryDetails?.masterInventory ? 'Yes' : 'No' || 'NA'}</span>
      </div>
    </div>
  )
}

export default LinkedInvCard
