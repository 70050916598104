import React, { useEffect } from 'react'

import { WppProgressIndicator } from '@wppopen/components-library-react'
import { useOs } from '@wppopen/react'
import { useDispatch, useSelector } from 'react-redux'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'

import '@wppopen/components-library/dist/platform-ui-kit/wpp-theme.css'

import styles from 'app/App.module.scss'

import { getOrgFail, getOrgReq, getOrgSuc, storeBase64Email, storeAccessToken, setOsContext } from './action'
import appConfig from '../app.config'
import ServiceDown from '../components/serviceDown/ServiceDown'
import UnAuthorized from '../components/unauthorized/UnAuthorized'
import CPPDashboard from '../containers/cppModule/cppDashboard/CPPDashboard'
import FlowChart from '../containers/cppModule/flowchart/flowChart'
import DMDashboard from '../containers/dmModule/dmDashboard/DMDashboard'
import InventoryList from '../containers/dmModule/inventoryList/InventoryList'
import Reports from '../containers/dmModule/reports/Reports'
import LandingPage from '../containers/landingPage/LandingPage'
import AssessmentList from '../containers/piaModule/assessmentList/AssessmentList'
import PIADashboard from '../containers/piaModule/piaDashboard/PIADashboard'
import RiskRegister from '../containers/piaModule/riskRegister/RiskRegister'
import Templates from '../containers/piaModule/templates/Templates'
import { selectedSideNavOrg } from '../containers/sideNav/action'
import SideNavWpp from '../containers/sideNav/SideNavWpp'
import TPRMDashboard from '../containers/tprmModule/tprmDashboard/TPRMDashboard'
import VendorsList from '../containers/tprmModule/vendorsList/VendorsList'
import { USER_ROLE } from '../helper/constants'
import { serviceURL } from '../helper/serviceURL'
import useAxiosInterceptors from '../hooks/useAxiosInterceptors'
export const App = () => {
  /**
   * This hook is provided by the utility package for more convenient work with OS context
   * @return osContext
   * - is a subscribe function that returns you the main context object with MicroAppContext data
   * @return osApi
   * - methods to fire actions on the parent OS level
   *
   * Read more about it in the README file
   */
  const { axiosInstance } = useAxiosInterceptors()
  const userRoles = useSelector((state: any) => state.getOrgByUserRed)
  const {
    osContext,
    osApi: { getAccessToken }
  } = useOs()
  const { email } = osContext.userDetails
  const dispatch = useDispatch()
  useEffect(() => {
    try {
      dispatch(getOrgReq())

      const base64Email = btoa(`${email}:${appConfig.RA_PASSWORD}`)
      const token = getAccessToken()
      const apiUrl = serviceURL.pgpBaseAPI + '/api/access/user/' + email
      const postData = { token: getAccessToken(), accountId: osContext['permissions'][0]['account_id'].trim() }
      axiosInstance
        .post(apiUrl, postData, {
          headers: {
            accept: '*/*'
          }
        })
        .then((res: any) => {
          dispatch(getOrgSuc(res.data))
          let userAgency = res.data['organizations'].filter((o: any) => o.id === res.data['organizationId'])[0]
          if (osContext?.userDetails) {
            let userDetails = { ...osContext.userDetails }
            userDetails['agency'] = userAgency.name
            userDetails['id'] = userAgency.id
            dispatch(setOsContext(userDetails))
          }

          const initOrg = initialOrg(res.data, userAgency.name)
          dispatch(selectedSideNavOrg(initOrg))
        })
        .catch(error => {
          console.log(error)
          dispatch(getOrgFail(error))
        })
      dispatch(storeBase64Email(base64Email))
      dispatch(storeAccessToken(token))
    } catch (error) {
      console.error('Error dispatching action: ', error)
    }
  }, [dispatch, email, axiosInstance, getAccessToken, osContext])

  const initialOrg = (orgList: { organizations: [{ name: string }] }, userAgency: string) => {
    return orgList?.organizations?.length
      ? orgList?.organizations?.find(org => org?.name?.toLowerCase() === userAgency?.toLowerCase()) || {}
      : {}
  }

  const routes = [
    {
      path: '/welcome',
      element: <LandingPage />,
      allowedRoles: [USER_ROLE.SAD]
    },
    {
      path: '/cpp/cpp-dashboard',
      element: <CPPDashboard />,
      allowedRoles: [USER_ROLE.CPPA]
    },
    {
      path: '/cpp/flowchart',
      element: <FlowChart />,
      allowedRoles: [USER_ROLE.CPPA]
    },
    {
      path: '/dm/dm-dashboard',
      element: <DMDashboard />,
      allowedRoles: [USER_ROLE.SAD, USER_ROLE.IM, USER_ROLE.VM]
    },
    {
      path: '/dm/data-mapping',
      element: <InventoryList />,
      allowedRoles: [USER_ROLE.SAD, USER_ROLE.IM, USER_ROLE.VM]
    },
    {
      path: '/dm/reports',
      element: <Reports />,
      allowedRoles: [USER_ROLE.SAD, USER_ROLE.IM, USER_ROLE.VM]
    },
    {
      path: '/dashboard/assessment-dashboard',
      element: <PIADashboard />,
      allowedRoles: [USER_ROLE.SAD, USER_ROLE.VM, USER_ROLE.DPM, USER_ROLE.IM]
    },
    {
      path: '/vrm/assessments-list',
      element: <AssessmentList />,
      allowedRoles: [USER_ROLE.SAD, USER_ROLE.VM, USER_ROLE.DPM, USER_ROLE.IM]
    },
    {
      path: '/vrm/assessments-list/:assessmentId',
      element: <AssessmentList />,
      allowedRoles: [USER_ROLE.SAD, USER_ROLE.VM, USER_ROLE.DPM, USER_ROLE.IM]
    },
    {
      path: '/pia/risk-register',
      element: <RiskRegister />,
      allowedRoles: [USER_ROLE.SAD, USER_ROLE.VM, USER_ROLE.DPM, USER_ROLE.IM]
    },
    {
      path: '/system/templates',
      element: <Templates />,
      allowedRoles: [USER_ROLE.SAD, USER_ROLE.VM, USER_ROLE.DPM, USER_ROLE.IM]
    },
    {
      path: '/dashboard/vendor-dashboard',
      element: <TPRMDashboard />,
      allowedRoles: [USER_ROLE.SAD, USER_ROLE.IM, USER_ROLE.AM, USER_ROLE.VM, USER_ROLE.DPM, USER_ROLE.SU]
    },
    {
      path: '/vrm/vendors-list',
      element: <VendorsList />,
      allowedRoles: [USER_ROLE.SAD, USER_ROLE.IM, USER_ROLE.AM, USER_ROLE.VM, USER_ROLE.DPM, USER_ROLE.SU]
    },
    {
      path: '/vrm/vendors-list/:inventoryId',
      element: <VendorsList />,
      allowedRoles: [USER_ROLE.SAD, USER_ROLE.IM, USER_ROLE.AM, USER_ROLE.VM, USER_ROLE.DPM, USER_ROLE.SU]
    },
    {
      path: '/unauthorized',
      element: <UnAuthorized />,
      allowedRoles: [USER_ROLE.SAD, USER_ROLE.IM, USER_ROLE.VM, USER_ROLE.DPM]
    }
  ]

  const checkAuthorization = (allowedRoles: any) => {
    return userRoles?.data?.groups?.some((item: any) => allowedRoles.includes(item.name))
  }

  if (userRoles.loading) {
    return <WppProgressIndicator variant="bar" className={styles.customLoader} />
  } else if (userRoles.data) {
    return (
      <BrowserRouter basename={osContext.baseUrl}>
        <div className={styles.container}>
          <Routes>
            <Route path="/welcome" element={<LandingPage />} />
            <Route path="/unauthorized" element={<LandingPage />} />
            <Route
              path="/*"
              element={
                <div className={styles.adminContainer}>
                  <nav>
                    <SideNavWpp />
                  </nav>
                  <div className={styles.content}>
                    <Routes>
                      {routes.map((route, index) => (
                        <Route
                          key={index}
                          path={route.path}
                          element={checkAuthorization(route.allowedRoles) ? route.element : <Navigate to="/welcome" />}
                        />
                      ))}
                      <Route index element={<Navigate to="/welcome" />} />
                    </Routes>
                  </div>
                </div>
              }
            />
          </Routes>
        </div>
      </BrowserRouter>
    )
  } else if (userRoles.error) {
    return <ServiceDown />
  }

  return null
}

export default App
