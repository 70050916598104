import React, { useLayoutEffect, useState } from 'react'

import { Typography } from '@mui/material'
import {
  WppDivider,
  WppIconChannel,
  WppIconDatabase,
  WppIconDataViewList,
  WppIconLink,
  WppIconLockOn,
  WppIconNote,
  WppNavSidebar,
  WppNavSidebarItem,
  WppIconDataHistogram
} from '@wppopen/components-library-react'
import _ from 'lodash'
import { connect } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'

import { selectedSideNavOrgDispatcher } from './action'
import styles from './SideNavWpp.module.scss'
import appConfig from '../../app.config'
import { USER_ROLE, PROD, NPROD, DATA_MAPPING } from '../../helper/constants'

import '../../assets/scss/App.scss'

const mapStateToProps = (state: any) => {
  return {
    orgList: state.getOrgByUserRed.data,
    selectedOrg: state.selectedSideNavOrgRed.data
  }
}
const mapDispatchToProps = (dispatch: any) => ({
  selectedSideNavOrgDispatcher: (org: object) => dispatch(selectedSideNavOrgDispatcher(org))
})

interface SideNavProps {
  selectedSideNavOrgDispatcher: (org: object) => void
  orgList?: { organizations: []; groups: [] }
  selectedOrg?: string
}

interface ParentProps {
  parentLabel: string
  parentPath: string
  title: string
  isAuthorised: boolean
  childMenu?: object[]
  icon?: any
}

interface subModule1 {
  name: string
  active: boolean
}

interface selectedModuleProps {
  name: string
  active: boolean
  expanded?: boolean
  subModule1?: subModule1
}

let initialSelectedModule = { name: '', expanded: false, active: false, subModule1: { name: '', active: false } }
export const SideNavWpp = ({ selectedSideNavOrgDispatcher, orgList, selectedOrg }: SideNavProps) => {
  const app_ver = appConfig.UI_VERSION
  const base_url = appConfig.RA_HOST_URL
  console.log('App Version >>>>>', app_ver)
  console.log('Base urls >>>>>', base_url)
  const location = useLocation()
  const [selectedModule, setSelectedModule] = useState<selectedModuleProps>(initialSelectedModule)
  const navigate = useNavigate()
  const activeIndicator = (fixedItem: any, dynamicItem: selectedModuleProps, level: number, action: string) => {
    let selectedModuleIndicator: any = {
      name: '',
      expanded: false,
      active: false,
      subModule1: { name: '', active: false }
    }
    let parentPath = ''
    let childOneLevelPath = ''
    const returnDefault = (actionType: string) => {
      if (actionType === 'checkActive') {
        return (selectedModuleIndicator.active = false)
      } else if (actionType === 'checkExpand') {
        return (selectedModuleIndicator.expanded = false)
      }
    }
    switch (level) {
      case 1:
        if (_.isEqual(dynamicItem, initialSelectedModule)) {
          returnDefault(action)
        } else {
          parentPath =
            fixedItem.parentPath && fixedItem.parentPath.split('/').filter((item: string) => item && item.length > 0)[0]
          selectedModuleIndicator['active'] = dynamicItem.name && parentPath === dynamicItem.name
          selectedModuleIndicator['expanded'] =
            dynamicItem.name && parentPath === dynamicItem.name && fixedItem?.childMenu?.length > 0
          if (action === 'checkActive') {
            return selectedModuleIndicator.active
          } else if (action === 'checkExpand') {
            return selectedModuleIndicator.expanded
          }
        }
        break
      case 2:
        parentPath = fixedItem.path.split('/').filter((item: string) => item && item.length > 0)[0]
        childOneLevelPath = fixedItem.path.split('/').filter((item: string) => item && item.length > 0)[1]
        if (
          dynamicItem.subModule1 &&
          dynamicItem.subModule1.name &&
          parentPath === dynamicItem.name &&
          childOneLevelPath === dynamicItem.subModule1.name
        ) {
          return (selectedModuleIndicator['subModule1'] = { active: true })
        }
        break
      default:
        returnDefault(action)
        break
    }
  }

  const iconList = [
    { name: 'WppIconDataViewList', icon: <WppIconDataViewList slot="icon-start" /> },
    { name: 'WppIconLink', icon: <WppIconLink slot="icon-start" /> },
    { name: 'WppIconNote', icon: <WppIconNote slot="icon-start" /> },
    { name: 'WppIconChannel', icon: <WppIconChannel slot="icon-start" /> },
    { name: 'WppIconDatabase', icon: <WppIconDatabase slot="icon-start" /> },
    { name: 'WppIconLockOn', icon: <WppIconLockOn slot="icon-start" color="#B01C1C" /> },
    { name: 'WppIconDataHistogram', icon: <WppIconDataHistogram slot="icon-start" /> }
  ]

  const isUserAuthorized = (arr1: any, arr2: any) => {
    if (arr1) {
      const isUserAuth = arr1.some((item: any) => arr2.includes(item.name))
      return !isUserAuth
    }
    return true
  }

  const menuConfig = [
    {
      parentLabel: 'Consumer Preference Portal',
      parentPath: '/cpp/cpp-dashboard',
      title: 'Privacy',
      icon: 'WppIconDataViewList',
      isAuthorised: isUserAuthorized(orgList ? orgList.groups : [], [USER_ROLE.CPPA]),
      childMenu: [
        { label: 'Dashboard', path: '/cpp/cpp-dashboard' },
        // {label: 'Resolve DSAR', path: '/cpp/resolve-dsar'}, Enable after API changes in place
        { label: 'Flowchart', path: '/cpp/flowchart' }
      ]
    },
    {
      parentLabel: 'Data Mapping',
      parentPath: '/dm/dm-dashboard',
      title: 'Mapping',
      icon: 'WppIconLink',
      isAuthorised: isUserAuthorized(orgList ? orgList.groups : [], [USER_ROLE.DPM]),
      childMenu: [
        { label: 'Dashboard', path: '/dm/dm-dashboard' },
        { label: 'Inventory', path: '/dm/data-mapping' },
        { label: 'Reports', path: '/dm/reports' }
      ]
    },
    // {
    //     parentLabel: 'Privacy Review',
    //     parentPath: '/pia/pia-dashboard',
    //     title: 'Assessments',
    //     icon: 'WppIconNote',
    //     isAuthorised: isUserAuthorized(orgList ? orgList.groups : [],
    //         [USER_ROLE.SAD, USER_ROLE.VM, USER_ROLE.DPM, USER_ROLE.IM]),
    //     childMenu: [
    //         {label: 'Dashboard', path: '/pia/pia-dashboard'},
    //         {label: 'Assessment', path: '/pia/assessment'},
    //         // {label: 'Risk Register', path: '/pia/risk-register'},
    //         // {label: 'Reports', path: '/pia/reports'},
    //         {label: 'Templates', path: '/pia/templates'},
    //     ]
    // },
    {
      parentLabel: 'Vendor Risk Management',
      parentPath: '/vrm/vendors-list',
      title: 'Vendor Risk',
      icon: 'WppIconChannel',
      isAuthorised: isUserAuthorized(orgList ? orgList.groups : [], [
        USER_ROLE.SAD,
        USER_ROLE.IM,
        USER_ROLE.AM,
        USER_ROLE.VM,
        USER_ROLE.DPM,
        USER_ROLE.SU
      ]),
      childMenu: [
        // {label: 'Dashboard', path: '/tprm/tprm-dashboard'},
        { label: 'Vendors', path: '/vrm/vendors-list' },
        { label: 'Assessments', path: '/vrm/assessments-list' }
        // {label: 'Reports', path: '/tprm/reports'},
      ]
    },
    {
      parentLabel: 'Dashboard',
      parentPath: '/dashboard/vendor-dashboard',
      title: 'Dashboard',
      icon: 'WppIconDataHistogram',
      isAuthorised: isUserAuthorized(orgList ? orgList.groups : [], [USER_ROLE.SAD, USER_ROLE.DPM]),
      childMenu: [
        { label: 'Vendor', path: '/dashboard/vendor-dashboard' },
        { label: 'Assessment', path: '/dashboard/assessment-dashboard' }
      ]
    },
    {
      parentLabel: 'Template',
      parentPath: '/system/templates',
      title: 'Template',
      icon: 'WppIconNote',
      isAuthorised: isUserAuthorized(orgList ? orgList.groups : [], [USER_ROLE.SAD, USER_ROLE.DPM]),
      childMenu: [{ label: 'Templates', path: '/system/templates' }]
    }
  ]

  const handleChangeRoute = (event: any) => {
    navigate(event.detail.path)
  }

  useLayoutEffect(() => {
    const modifiedLocation = location.pathname.split('/').filter(item => item.length > 0)
    let dynamicItem: any = {}
    for (const [key, value] of Object.entries(modifiedLocation)) {
      if (+key === 0) {
        dynamicItem['name'] = value
      } else if (+key > 0) {
        dynamicItem['expanded'] = true
        if (+key === 1) {
          dynamicItem['subModule1'] = { name: value }
        }
      }
    }
    setSelectedModule(dynamicItem)
  }, [location])

  const getVersionNumber = (str: string) => {
    if (str !== null && str !== undefined) {
      const parts = str.split('-')
      const version = parts[parts.length - 1]
      return version
    } else {
      return ''
    }
  }

  return (
    <div className={styles.primaryNav}>
      <WppNavSidebar initialPath={location.pathname} onWppChange={handleChangeRoute}>
        <div className={styles.sideNavHead}>
          <Typography variant="h4">WPP Safer Data Navigator</Typography>
        </div>
        <WppDivider />
        {menuConfig &&
          menuConfig
            ?.filter(
              (parent: ParentProps) =>
                (appConfig.NPROD === PROD && parent.parentLabel !== DATA_MAPPING) ||
                appConfig.NPROD === NPROD
            )
            .filter(Boolean)
            .map((parent: ParentProps, parentIdx: number) => {
              return (
                <React.Fragment key={parentIdx}>
                  <WppNavSidebarItem
                    key={parentIdx}
                    maxTitleLengthWithSubItems={30}
                    label={parent.parentLabel}
                    path={parent.parentPath}
                    groupTitle={parent.title}
                    active={activeIndicator(parent, selectedModule, 1, 'checkActive')}
                    expanded={activeIndicator(parent, selectedModule, 1, 'checkExpand')}
                    extended={parent.childMenu && parent.childMenu.length > 0}
                  >
                    <>
                      {!parent.isAuthorised
                        ? iconList.find(icon => icon.name === parent.icon)?.icon
                        : iconList[5]?.icon}
                    </>
                    {parent &&
                      parent.childMenu &&
                      parent.childMenu.length &&
                      parent.childMenu.map((child: any, childIdx: number) => (
                        <WppNavSidebarItem
                          className={`${
                            !parent.isAuthorised
                              ? styles.customNavItemCategory
                              : styles.customNavItemCategory + ' ' + styles.disable
                          }`}
                          key={`${parentIdx}-${childIdx}`}
                          label={child.label}
                          active={activeIndicator(child, selectedModule, 2, 'checkActive')}
                          path={child.path}
                        >
                          <>{parent.isAuthorised && iconList[5]?.icon}</>
                        </WppNavSidebarItem>
                      ))}
                  </WppNavSidebarItem>
                  <WppDivider />
                </React.Fragment>
              )
            })}
        <p className={styles.uiVersion}>Build Version: {getVersionNumber(appConfig.UI_VERSION)}</p>
      </WppNavSidebar>
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(SideNavWpp)
