import { Card, Divider, Typography } from '@mui/material'

interface DashboardCardsProps {
  color?: string
  icon?: string
  title: string
  total?: number
  sx?: object
  count?: number
  bgcolor?: string
  height?: string
}

export default function DashboardCards({
  count,
  bgcolor,
  title,
  total,
  icon,
  height,
  sx,
  ...other
}: DashboardCardsProps) {
  return (
    <Card
      sx={{
        py: 5,
        boxShadow: '0 4px 12px 0 rgba(94, 118, 181, 0.1)',
        borderRadius: 2,
        height: height || '100%',
        textAlign: 'center',
        bgcolor,
        ...sx
      }}
      {...other}
    >
      <Typography variant="subtitle1" sx={{ opacity: 1, fontSize: 16, lineHeight: 1.5, fontWeight: 600 }}>
        {title}
      </Typography>
      <br />
      <Divider />
      <br />
      <Typography
        variant="subtitle2"
        sx={{
          opacity: 1,
          fontSize: 30,
          lineHeight: 1,
          color: 'black',
          fontWeight: 500
        }}
      >
        {count || 0}
      </Typography>
    </Card>
  )
}
